import React, { useRef, useState, useContext } from 'react';
import {
    SttButton,
    SttTranslateHook,
    SttGrid,
    SttInput,
    SttModal,
} from '@stt-componentes/core';
import { connect } from 'react-redux';
import axios from 'axios';
import { getHeaders } from '../../../../request';
import {
    Formik,
    FastField
} from 'formik';
import {
    setParametrosAlerta as setParametrosAlertaAction,
    setOpen as setOpenAction
} from "../../../../reducers/actions/alerta";
import HttpStatus from 'http-status-codes';
import validationSchema from './validationSchema';
import { mostrarCarregando as mostrarCarregandoAction } from '../../../../reducers/actions/carregando';

const initialValues = {
    pergunta: '',
    resposta: ''
}

const ListaEquipePerguntaForm = ({ idWebconferencia, equipeSaude, callbackCancelar, setParametrosAlerta, setOpen, mostrarCarregando }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema(strings);
    const formRef = useRef();

    const [modalOpen, setModalOpen] = useState(true);

    const enviarPergunta = (dados, setSubmitting) => {
        dados.idWebconferencia = idWebconferencia;
        dados.idEquipeSaude = equipeSaude.id_equipe_saude_familia;
        setSubmitting(true);
        mostrarCarregando(true, strings.salvandoMensagemEspera);
        axios.post(`${global.gConfig.url_base_tele_educacao}/webconferencia/pergunta-equipe`, dados, { headers: getHeaders() })
            .then((response) => {
                const parametrosAlerta = {
                    titulo: strings.sucesso,
                    mensagem: strings.sucessoRegistroPergunta,
                    tipo: 'success',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setOpen(false);
                                setModalOpen(false);
                                callbackCancelar();
                            }

                        }
                    ]
                };
                setParametrosAlerta(parametrosAlerta);
            })
            .catch(err => {
                const { response } = err;
                let mensagem = strings.erro;
                if (response) {
                    const { data } = response;

                    mensagem = data.message;
                    if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagem = arrMensagem.join('\n');
                        }
                    }
                }

                const parametrosAlerta = {
                    titulo: strings.erro,
                    mensagem: mensagem,
                    tipo: 'error',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setOpen(false);
                            }

                        }
                    ]
                };
                setParametrosAlerta(parametrosAlerta);
            })
            .finally(() => {
                mostrarCarregando(false);
                if (setSubmitting) {
                    setSubmitting(false);
                }
            });
    }

    return (
        <SttModal
            title={`${strings.novaPergunta} - ${equipeSaude.nome_equipe}`}
            open={modalOpen}
            maxWidth="md"
            fullWidth={true}
            outModalClose={callbackCancelar}
            iconClose={callbackCancelar}
            children={
                <Formik
                    innerRef={formRef}
                    initialValues={initialValues}
                    validationSchema={schema}
                    onSubmit={(dados, { setSubmitting }) => {
                        setSubmitting(true);
                        enviarPergunta(dados, setSubmitting);
                    }}
                >
                    {
                        ({
                            handleSubmit,
                        }) => (
                            <>
                                <form onSubmit={handleSubmit} noValidate>
                                    <SttGrid container spacing={1}>
                                        <SttGrid item xs={12}>
                                            <FastField name="pergunta">
                                                {({
                                                    meta,
                                                    field,
                                                }) => (
                                                    <SttInput
                                                        {...field}
                                                        multiline
                                                        required={true}
                                                        minRows={3}
                                                        maxRows={6}
                                                        label={strings.pergunta}
                                                        error={meta.touched && meta.error ? true : false}
                                                        helperText={meta.touched && meta.error ? meta.error : undefined}
                                                    />
                                                )}
                                            </FastField>
                                        </SttGrid>
                                        <SttGrid item xs={12}>
                                            <FastField name="resposta">
                                                {({
                                                    meta,
                                                    field,
                                                }) => (
                                                    <SttInput
                                                        {...field}
                                                        multiline
                                                        minRows={3}
                                                        maxRows={6}
                                                        label={strings.resposta}
                                                        error={meta.touched && meta.error ? true : false}
                                                        helperText={meta.touched && meta.error ? meta.error : undefined}
                                                    />
                                                )}
                                            </FastField>
                                        </SttGrid>
                                    </SttGrid>
                                </form>
                            </>
                        )
                    }
                </Formik >
            }
            footer={
                <>
                    <SttButton variant="contained"
                        disabled={formRef.current && formRef.current.isSubmitting}
                        color="primary"
                        onClick={() => {
                            formRef.current.submitForm();
                        }}>
                        {strings.confirmar}
                    </SttButton>
                    <SttButton variant="outlined"
                        color="primary"
                        onClick={() => {
                            setModalOpen(false);
                            callbackCancelar();
                        }}>
                        {strings.fechar}
                    </SttButton>
                </>

            }
        />
    )
};

const mapDispatchToProps = (dispatch) => {
    return {
        mostrarCarregando: (open, titulo) => dispatch(mostrarCarregandoAction(open, titulo)),
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open)),
    };
};

export default connect(null, mapDispatchToProps)(ListaEquipePerguntaForm);
