import * as yup from 'yup'

import dadosGeraisSchema from '../../../componentes/interno/cadastro/curso/dadosGerais/form/validationSchema';
import detalhesCursoSchema from '../../../componentes/interno/cadastro/curso/detalhesCurso/form/validationSchema';
import dadosDecsSchema from '../../../componentes/interno/cadastro/curso/dadosDecs/form/validationSchema';
import dadosAnexosSchema from '../../../componentes/interno/cadastro/curso/anexos/form/validationSchema';

export default (strings) => {
    const schema = yup.object().shape({});

    const dadosGerais = dadosGeraisSchema(strings);
    const detalhesCurso = detalhesCursoSchema(strings);
    const dadosDecs = dadosDecsSchema(strings);
    const dadosAnexos = dadosAnexosSchema(strings);

    return schema
        .concat(dadosGerais)
        .concat(detalhesCurso)
        .concat(dadosDecs)
        .concat(dadosAnexos);
}