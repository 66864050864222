import React, { useState, useContext } from "react";
import {
    SttButton,
    SttModal,
    SttTranslateHook
} from '@stt-componentes/core';
import FormOpcoes from './form-opcoes';

const ModalAvaliacao = ({ setModalAvaliacao, avaliacaoEdicao, webconferenciaAvaliacao, setRecarregarWebconferencia }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const [ref, setRef] = useState(null);

    return (
        <SttModal
            title={strings.tituloModalAvaliacao}
            open={true}
            maxWidth="lg"
            outModalClose={() => setModalAvaliacao(false)}
            iconClose={() => setModalAvaliacao(false)}
            fullWidth={true}
            children={
                <FormOpcoes
                    setModalAvaliacao={setModalAvaliacao}
                    avaliacaoEdicao={avaliacaoEdicao}
                    webconferenciaAvaliacao={webconferenciaAvaliacao}
                    setRecarregarWebconferencia={setRecarregarWebconferencia}
                    setRef={setRef}
                />
            }
            footer={
                <div>
                    <SttButton
                        variant="contained"
                        color="primary"
                        nomarginleft="true"
                        disabled={ref?.current?.isSubmitting}
                        onClick={() => {
                            if (ref?.current) {
                                ref.current.handleSubmit();
                            }
                        }}
                    >
                        {avaliacaoEdicao ? strings.alterar : strings.adicionar}
                    </SttButton>
                    <SttButton variant="contained"
                        color="secondary"
                        onClick={() => setModalAvaliacao(false)}>
                        {strings.fechar}
                    </SttButton>
                </div>
            }
        />
    );
}

export default ModalAvaliacao;