import React, { useEffect, useState, useRef, lazy, Suspense, memo, useContext } from 'react';
import { connect } from 'react-redux';
import {
    SttTabs,
    SttCircularProgress,
    SttTranslateHook
} from '@stt-componentes/core';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(1.5)
    },
}));

const Temas = lazy(() => import('../../componentes/administrativo/temas/pesquisa'));
const Salas = lazy(() => import('../../componentes/administrativo/salas/pesquisa'));
const CategoriasPublicoAlvo = lazy(() => import('../../componentes/administrativo/publicos-alvo/pesquisa'));
const TiposAtividade = lazy(() => import ('../../componentes/administrativo/tipos-atividade/pesquisa'))

const TemasSuspense = memo((props) => {
    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <Temas {...props} />
        </Suspense>
    )
});

const SalasSuspense = memo((props) => {
    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <Salas {...props} />
        </Suspense>
    )
});

const CategoriasPublicoAlvoSuspense = memo((props) => {
    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <CategoriasPublicoAlvo {...props} />
        </Suspense>
    )
});

const TiposAtividadeSuspense = memo((props) => {
    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <TiposAtividade {...props} />
        </Suspense>
    )
});


const IndexAdministrativo = ({ user }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    const [abas, setAbas] = useState([]);
    const [abaAtiva, setAbaAtiva] = useState('0');
    const stateRef = useRef();

    stateRef.current = abas;

    const removerAba = (indice) => {
        setAbaAtiva('0');
        const i = indice[0];

        const dadosAbas = [
            ...stateRef.current.slice(0, i),
            ...stateRef.current.slice(i + 1)
        ];

        setTimeout(() => {
            setAbas(dadosAbas);
        }, 120);
    }

    useEffect(() => {
        let dadosAbas = [
            {
                titulo: strings.temas,
                conteudo: TemasSuspense,
                permanente: true,
                key: 'TemasSuspense'
            },
            {
                titulo: strings.salas,
                conteudo: SalasSuspense,
                permanente: true,
                key: 'SalasSuspense'
            },
            {
                titulo: strings.publicoAlvo,
                conteudo: CategoriasPublicoAlvoSuspense,
                permanente: true,
                key: 'CategoriasPublicoAlvoSuspense'
            },
            {
                titulo: strings.tiposAtividade,
                conteudo: TiposAtividadeSuspense,
                permanente: true,
                key: 'TiposAtividade'
            },
        ];

        setAbas(dadosAbas);
    }, [user]);

    return (
        <SttTabs abas={abas}
            canClose={true}
            className={classes.wrapper}
            handleChangeAbaAtiva={setAbaAtiva}
            abaAtiva={abaAtiva}
            handleCloseTab={(indice) => removerAba(indice)}
            permanente={true}
            preload={false}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
    };
};

export default connect(mapStateToProps)(IndexAdministrativo);