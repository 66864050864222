import * as yup from 'yup';
import {
    CARGA_HORARIA,
    CATEGORIA,
    DADOS_GERAIS,
    DESCRICAO,
    ENDERECO_CURSO,
    FORMATO_ATIVIDADE,
    FORMATO_INSTRUCAO,
    INSTITUICAO_RESPONSAVEL,
    PUBLICO,
    PUBLICOS_ALVO,
    TITULO,
    TITULO_BREVE,
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [DADOS_GERAIS]: yup.object().shape({
            [CATEGORIA]: yup
                .object().shape({
                    id: yup.string(),
                    descricao: yup.string(),
                })
                .nullable()
                .required(strings.campoObrigatorio),
            [FORMATO_INSTRUCAO]: yup
                .object().shape({
                    identificador: yup.string(),
                    descricao: yup.string(),
                })
                .nullable()
                .required(strings.campoObrigatorio),
            [FORMATO_ATIVIDADE]: yup
                .object().shape({
                    id: yup.string(),
                    descricao: yup.string(),
                    ordenacao: yup.number(),
                    ativo: yup.boolean()
                })
                .nullable()
                .required(strings.campoObrigatorio),
            [INSTITUICAO_RESPONSAVEL]: yup
                .object().shape({
                    id: yup.number(),
                    nome: yup.string(),
                })
                .nullable()
                .required(strings.campoObrigatorio),
            [TITULO]: yup
                .string()
                .trim()
                .min(10, strings.tituloInvalidoMin)
                .max(255, strings.erroMaxCaracteres(255, strings.titulo))
                .required(strings.campoObrigatorio),
            [TITULO_BREVE]: yup
                .string()
                .trim()
                .min(10, strings.tituloInvalidoMin)
                .max(100, strings.erroMaxCaracteres(100, strings.titulo))
                .required(strings.campoObrigatorio),
            [DESCRICAO]: yup
                .string()
                .trim()
                .min(10, strings.descricaoInvalidaMin)
                .required(strings.campoObrigatorio),
            [ENDERECO_CURSO]: yup
                .string()
                .trim()
                .url(strings.urlInvalida)
                .required(strings.campoObrigatorio),
            [CARGA_HORARIA]: yup
                .number()
                .min(1, strings.cargaHorariaMinima)
                .required(strings.campoObrigatorio),
            [PUBLICOS_ALVO]: yup.array().when(PUBLICO, {
                is: (val) => !val,
                then: yup.array().of(
                    yup.object().shape({
                        id: yup.number(),
                        descritor: yup.string()
                    }).required()
                ).min(1, strings.cboObrigatorioCurso)
                    .required()
            }).nullable(),
        }).required()
    });
}
