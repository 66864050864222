import React, { useState, useContext } from "react";
import {
    SttButton,
    SttModal,
    SttTranslateHook
} from '@stt-componentes/core';
import FormOpcoes from './form-opcoes';

const ModalMaterial = ({ setBuscar, setModalMaterial, materialEdicao, idWebconferencia, setRecarregarWebconferencia }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const [ref, setRef] = useState(null);

    return (
        <SttModal
            title={strings.tituloModalMaterial}
            open={true}
            maxWidth="md"
            outModalClose={() => setModalMaterial(false)}
            iconClose={() => setModalMaterial(false)}
            fullWidth={true}
            children={
                <FormOpcoes
                    setModalMaterial={setModalMaterial}
                    materialEdicao={materialEdicao}
                    idWebconferencia={idWebconferencia}
                    setRecarregarWebconferencia={setRecarregarWebconferencia}
                    setRef={setRef}
                    setBuscar={setBuscar}
                />
            }
            footer={
                <div>
                    <SttButton
                        variant="contained"
                        color="primary"
                        nomarginleft="true"
                        disabled={ref?.current?.isSubmitting}
                        onClick={() => {
                            if (ref?.current) {
                                ref.current.handleSubmit();
                            }
                        }}
                    >
                        {materialEdicao ? strings.alterar : strings.adicionar}
                    </SttButton>
                    <SttButton variant="contained"
                        color="secondary"
                        onClick={() => setModalMaterial(false)}>
                        {strings.fechar}
                    </SttButton>
                </div>
            }
        />
    );
}

export default ModalMaterial;