import React, { useEffect, useState, useContext } from "react";
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import axios from 'axios';
import { getHeaders } from '../../../../../request';
import { 
    SttTranslateHook,
    SttTable,
    SttTableHead,
    SttTableRow,
    SttHidden,
    SttTableCell,
    SttTableSortLabel,
    SttTableBody,
    SttCircularProgress,
    SttText,
    SttTablePagination,
    SttDeleteButton
} from '@stt-componentes/core';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import httpStatus from 'http-status-codes';
import { mostrarCarregando as mostrarCarregandoAction } from '../../../../../reducers/actions/carregando';
import {
    setParametrosAlerta as setParametrosAlertaAction,
    setOpen as setOpenAction
} from '../../../../../reducers/actions/alerta';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    },
    chip: {
        '& span': {
            whiteSpace: 'pre-wrap'
        },
        height: 'auto',
        textAlign: 'center'
    },
    heading: {
        marginTop: theme.spacing(2.5),
        padding: theme.spacing(1),
        textAlign: 'center'
    },
    labelCheckboxModoSmartphone: {
        marginLeft: 0
    }
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        FUNCIONARIO: 'funcionario',
        DATA: 'data'
    }
}

const HistoricoAcessos = ({ 
    idWebconferencia, 
    atualizarBusca, 
    setAtualizarBusca,
    setParametrosAlerta, 
    mostrarCarregando, 
    setOpen, 
}) => {

    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    const TELE_EDUCACAO_API_BASE_URL = global.gConfig.url_base_tele_educacao;

    const [historicoAcessos, setHistoricoAcessos] = useState([]);
    const [totalRegistros, setTotalRegistros] = useState(0);
    const [page, setPage] = useState(global.gConfig.pagination.start);
    const [count, setCount] = useState(global.gConfig.pagination.count);
    const [sort, setSort] = useState(null);
    const [direction, setDirection] = useState(null);

    useEffect(() => {
        if (atualizarBusca) {
            const offset = (page * count);
            const params = {
                webconferencia: idWebconferencia,
                count,
                start: offset,
                sort,
                direction
            };
            axios
                .get(`${TELE_EDUCACAO_API_BASE_URL}/webconferencia/registro-acesso`, { params, headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        const { itens, totalRegistros } = response.data.data;
                        setTotalRegistros(parseInt(totalRegistros));
                        setHistoricoAcessos(itens);
                    }
                })
                .catch(err => console.log(err))
                .finally(() => {
                    setAtualizarBusca(false);
                });
        }
    }, [atualizarBusca]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setAtualizarBusca(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setCount(event.target.value);
        setPage(global.gConfig.pagination.start);
        setAtualizarBusca(true);
    };

    const handleClickSort = (campo) => {
        const isAsc = (sort === campo) && (direction === ORDENACAO.DIRECAO.ASC);
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        setSort(campo);
        setDirection(newOrder)
        setAtualizarBusca(true);
    };

    const preRemoverItemBibliotecaAcesso = (item) => {
        setParametrosAlerta({
            titulo: strings.atencao,
            mensagem: strings.confirmacaoRemoverItemBibliotecaAcesso,
            open: true,
            tipo: 'alert',
            opcoes: [
                {
                    title: strings.sim,
                    onClick: () => removerItemBibliotecaAcesso(item)
                },
                {
                    title: strings.nao,
                    onClick: () => setOpen(false)
                }
            ],
            onClose: () => setOpen(false)
        });
    }

    const removerItemBibliotecaAcesso = (item) => {
        mostrarCarregando(true);
        const data = {
            webconferencia: idWebconferencia,
            itemAcesso: item.id
        }
        axios
            .delete(`${TELE_EDUCACAO_API_BASE_URL}/webconferencia/registro-acesso`, { data, headers: getHeaders() })
            .then(() => {
                setParametrosAlerta({
                    titulo: strings.sucesso,
                    mensagem: strings.registroAcessoRemovido,
                    tipo: 'success',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                if ((page * count) === (totalRegistros - 1)) {
                                    setPage(page - 1);
                                }
                                setOpen(false);
                                setAtualizarBusca(true);
                            }
                        }
                    ]
                });
            })
            .catch(err => {
                const { response } = err;
                let mensagemAlertaErro = strings.mensagemErroGeral;

                if (response && (response.status === httpStatus.BAD_REQUEST)) {
                    const { data } = response;
                    let arrMensagem = [];
                    data.errors.forEach(error => {
                        arrMensagem.push(`- ${error.message}`);
                    });
                    mensagemAlertaErro = arrMensagem.join('\n');
                }

                const parametrosAlertaErro = {
                    titulo: strings.erro,
                    mensagem: mensagemAlertaErro,
                    tipo: 'error',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setOpen(false);
                            }
                        }
                    ]
                };

                setParametrosAlerta(parametrosAlertaErro);
            })
            .finally(() => {
                mostrarCarregando(false);
            });
    }

    return (
        <>
            <TableContainer className={classes.tableWrapper} component={Paper}>
                <SttTable>
                    <SttTableHead>
                        <SttTableRow>
                            <SttHidden smDown>
                                <SttTableCell width="70%" sortDirection={sort === ORDENACAO.CAMPOS.FUNCIONARIO ? direction : false}>
                                    <SttTableSortLabel
                                        active={sort === ORDENACAO.CAMPOS.FUNCIONARIO}
                                        direction={sort === ORDENACAO.CAMPOS.FUNCIONARIO ? direction : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.FUNCIONARIO)}
                                    >
                                        {strings.funcionario}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="20%" sortDirection={sort === ORDENACAO.CAMPOS.DATA ? direction : false}>
                                    <SttTableSortLabel
                                        active={sort === ORDENACAO.CAMPOS.DATA}
                                        direction={sort === ORDENACAO.CAMPOS.DATA ? direction : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA)}
                                    >
                                        {strings.data}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="10%" style={{ position: 'sticky', right: 0, backgroundColor: 'white', backgroundClip: 'padding-box', textAlign: 'center' }}>
                                    {strings.excluir}
                                </SttTableCell>
                            </SttHidden>
                            <SttHidden mdUp>
                                <SttTableCell width="100%" colSpan="2">{strings.acessos}</SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>
                    <SttTableBody>
                        {
                            atualizarBusca
                                ?
                                <SttTableRow key={-1}>
                                    <SttTableCell colSpan={3} align="center">
                                        <SttCircularProgress />
                                    </SttTableCell>
                                </SttTableRow>
                                :
                                (
                                    historicoAcessos.length === 0 ?
                                        <SttTableRow key={-1}>
                                            <SttTableCell colSpan={3}
                                                align="center">
                                                {strings.nenhumRegistroEncontrado}
                                            </SttTableCell>
                                        </SttTableRow>
                                        :
                                        historicoAcessos.map((row, index) => (
                                            <SttTableRow key={index}>
                                                <SttHidden smDown>
                                                    <SttTableCell>{row.funcionario}</SttTableCell>
                                                    <SttTableCell>{row.data_formatada}</SttTableCell>
                                                </SttHidden>
                                                <SttHidden mdUp>
                                                    <SttTableCell width="96%">
                                                        <SttText size="small">
                                                            <b>{strings.funcionario}:</b> {row.funcionario}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.data}:</b> {row.data_formatada}
                                                        </SttText>
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttTableCell align="center" style={{ position: 'sticky', right: 0, backgroundClip: 'padding-box' }}>
                                                    {
                                                        <SttDeleteButton
                                                            id={`btn-ver-solicitacao-${index}`}
                                                            onClick={() => preRemoverItemBibliotecaAcesso(row)}
                                                        />
                                                    }
                                                </SttTableCell>
                                            </SttTableRow>
                                        ))
                                )
                        }
                    </SttTableBody>
                </SttTable>
            </TableContainer>
            {
                historicoAcessos.length > 0 ?
                    (
                        <SttTablePagination rowsPerPageOptions={[10, 20, 40]}
                            component="div"
                            count={totalRegistros}
                            rowsPerPage={count}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={strings.linhasPorPagina}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${strings.de} ${count}`}
                        />
                    ) : null
            }
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open)),
        mostrarCarregando: (open, titulo) => dispatch(mostrarCarregandoAction(open, titulo))
    };
};

export default connect(null, mapDispatchToProps)(HistoricoAcessos);