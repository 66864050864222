import * as yup from 'yup';
import {
    ANEXO,
    ANEXOS,
    TIPO_ANEXO
} from './fieldNames';
import { MYME_TYPE_PERMITIDOS } from '../../../../../../common/Constants';

export default (strings) => {
    return yup.object().shape({
        [ANEXOS]: yup
            .array()
            .of(
                yup.object().shape({
                    [ANEXO]: yup
                        .mixed()
                        .nullable()
                        .test('tamanhoArquivo', strings.erroTamanhoArquivo10Mb, (value) => {
                            if (!value || !(value instanceof File)) {
                                return true;
                            }
                            return value?.size <= 10e6;
                        })
                        .test('formatoArquivo', strings.formatoArquivoInvalido, (value) => {
                            if (!value || !(value instanceof File)) {
                                return true;
                            }
                            return value && MYME_TYPE_PERMITIDOS.includes(value.type);
                        }),
                    [TIPO_ANEXO]: yup
                        .object().shape({
                            id: yup.string(),
                            descricao: yup.string(),
                            ordenacao: yup.number(),
                            sigla: yup.string(),
                            ativo: yup.boolean()
                        })
                        .nullable()
                        .required(strings.campoObrigatorio),
                })

            )
    });
}