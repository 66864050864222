import { AREA_TEMATICA, CATEGORIAS, CATEGORIAS_PUBLICOS_ALVO, DADOS_ATIVIDADE, TIPO_ATIVIDADE } from "../../componentes/interno/cadastro/webconferencia/dadosAtividade/form/fieldNames";
import { DECS } from "../../componentes/interno/cadastro/webconferencia/dadosDecs/form/fieldNames";
import { CARGA_HORARIA, DADOS_GERAIS, DATA_HORA, DESCRICAO, ENDERECO_SALA, FOTO, INSTITUICAO_RESPONSAVEL, PUBLICA, TITULO, ACEITA_CONVIDADO, PUBLICOS_ALVO } from "../../componentes/interno/cadastro/webconferencia/dadosGerais/form/fieldNames";
import { CBO_FAMILIA, CBO_OCUPACAO, DADOS_PALESTRANTE, NOME, SOBRE } from "../../componentes/interno/cadastro/webconferencia/dadosPalestrante/form/fieldNames";
import moment from 'moment';

const inicializarValoresFormulario = (dadosAlteracao) => {
    let formulario = {};
    formulario[DADOS_GERAIS] = {};
    formulario[DADOS_GERAIS][INSTITUICAO_RESPONSAVEL] = null;
    formulario[DADOS_GERAIS][ENDERECO_SALA] = '';
    formulario[DADOS_GERAIS][TITULO] = '';
    formulario[DADOS_GERAIS][DATA_HORA] = null;
    formulario[DADOS_GERAIS][DESCRICAO] = '';
    formulario[DADOS_GERAIS][CARGA_HORARIA] = '';
    formulario[DADOS_GERAIS][FOTO] = null;
    formulario[DADOS_GERAIS][PUBLICA] = true;
    formulario[DADOS_GERAIS][ACEITA_CONVIDADO] = true;
    formulario[DADOS_GERAIS][PUBLICOS_ALVO] = [];

    formulario[DADOS_PALESTRANTE] = {};
    formulario[DADOS_PALESTRANTE][NOME] = '';
    formulario[DADOS_PALESTRANTE][CBO_FAMILIA] = null;
    formulario[DADOS_PALESTRANTE][CBO_OCUPACAO] = null;
    formulario[DADOS_PALESTRANTE][SOBRE] = '';

    formulario[DADOS_ATIVIDADE] = {};
    formulario[DADOS_ATIVIDADE][AREA_TEMATICA] = null;
    formulario[DADOS_ATIVIDADE][CATEGORIAS] = [];
    formulario[DADOS_ATIVIDADE][CATEGORIAS_PUBLICOS_ALVO] = [];
    formulario[DADOS_ATIVIDADE][TIPO_ATIVIDADE] = null;

    formulario[DECS] = [];

    if (dadosAlteracao) {
        formulario.id = dadosAlteracao.id_webconferencia;

        if (dadosAlteracao.dataWebconferencia) {
            dadosAlteracao.dataWebconferencia = moment.utc(dadosAlteracao.dataWebconferencia);
        }

        formulario[DADOS_GERAIS][INSTITUICAO_RESPONSAVEL] = dadosAlteracao.instituicao;
        formulario[DADOS_GERAIS][ENDERECO_SALA] = dadosAlteracao.enderecoSala;
        formulario[DADOS_GERAIS][TITULO] = dadosAlteracao.titulo;
        formulario[DADOS_GERAIS][DATA_HORA] = dadosAlteracao.dataWebconferencia;
        formulario[DADOS_GERAIS][DESCRICAO] = dadosAlteracao.descricaoAgenda;
        formulario[DADOS_GERAIS][CARGA_HORARIA] = dadosAlteracao.cargaHoraria;
        formulario[DADOS_GERAIS][PUBLICOS_ALVO] = dadosAlteracao.publicosAlvo;

        if (dadosAlteracao.arquivo) {
            formulario[DADOS_GERAIS][FOTO] = {
                id: dadosAlteracao.arquivo.id,
                name: dadosAlteracao.arquivo.nome
            };
        }

        formulario[DADOS_GERAIS][PUBLICA] = dadosAlteracao.publica;
        formulario[DADOS_GERAIS][ACEITA_CONVIDADO] = dadosAlteracao.aceitaConvidados;

        formulario[DADOS_PALESTRANTE][NOME] = dadosAlteracao.nomePalestrante;
        formulario[DADOS_PALESTRANTE][CBO_FAMILIA] = dadosAlteracao.cboFamiliaPalestrante;
        formulario[DADOS_PALESTRANTE][CBO_OCUPACAO] = dadosAlteracao.cboOcupacaoPalestrante;
        formulario[DADOS_PALESTRANTE][SOBRE] = dadosAlteracao.descricaoPalestrante;

        if (dadosAlteracao.classe && dadosAlteracao.classe.id) {
            formulario[DADOS_ATIVIDADE][AREA_TEMATICA] = {
                id: dadosAlteracao.classe.id,
                descricao: dadosAlteracao.classe.descricao
            }
        }

        if (dadosAlteracao.identificador_cadastro_tipo_atividade && dadosAlteracao.descricaoTipoAtividade) {
            formulario[DADOS_ATIVIDADE][TIPO_ATIVIDADE] = {
                identificador: dadosAlteracao.identificador_cadastro_tipo_atividade,
                descricao: dadosAlteracao.descricaoTipoAtividade
            };
        }

        formulario[DADOS_ATIVIDADE][CATEGORIAS] = dadosAlteracao.categorias;
        formulario[DADOS_ATIVIDADE][CATEGORIAS_PUBLICOS_ALVO] = dadosAlteracao.categoriasPublicosAlvo;

        formulario[DECS] = dadosAlteracao.decs;
    }

    return formulario;
}

const formatarCadastro = (dados) => {
    const formData = new FormData();

    if (dados.id) {
        formData.append('id', dados.id);
    }

    if (dados.dadosGerais.dataHora._isAMomentObject) {
        dados.dadosGerais.dataHoraTimestamp = dados.dadosGerais.dataHora.toDate().getTime();
    } else {
        dados.dadosGerais.dataHoraTimestamp = dados.dadosGerais.dataHora.getTime();
    }
    formData.append('dadosGerais', JSON.stringify(dados.dadosGerais));
    formData.append('dadosPalestrante', JSON.stringify(dados.dadosPalestrante));
    formData.append('dadosAtividade', JSON.stringify(dados.dadosAtividade));
    formData.append('decs', JSON.stringify(dados.decs));

    let arq = dados.dadosGerais.foto;
    if (arq) {
        if (arq.id) {
            formData.append('idArquivo', arq.id);
        } else {
            formData.append('foto', arq);
        }
    }
    return formData;
};

const verificarErrosOrdemPrecedencia = (erros, secao) => {
    const ordemPrecedencia = [
        DADOS_GERAIS,
        DADOS_PALESTRANTE,
        DADOS_ATIVIDADE,
        DECS
    ];

    for (const ordem of ordemPrecedencia) {
        if (secao === ordem) {
            return false;
        }

        if (erros[ordem]) {
            return true;
        }
    }
}

const tratarUrlWebconferencia = (url) => {
    try {
        // Adiciona o esquema "https://" se a URL não tiver um esquema especificado
        if (!url.match(/^[a-z]+:\/\//i)) {
            url = 'https://' + url;
        }

        const urlObj = new URL(url);

        if (urlObj.protocol === '') {
            urlObj.protocol = 'https:';
        }

        return urlObj.toString();
    } catch (e) {
        console.error('Erro ao tratar a URL:', e);
        return null;
    }
}

const carregarImagemSrc = async (id, imagemCache, src) => {
    return new Promise((resolve, reject) => {
        if (imagemCache[id]) {
            resolve(JSON.stringify(JSON.parse(imagemCache)));
        } else {
            const image = new Image();
            image.src = src;

            image.onload = () => {
                imagemCache[id] = image;
                resolve(imagemCache);
            };

            image.onerror = () => {
                reject(new Error('Erro ao carregar a imagem'));
            };
        }
    });
}

const filtrarObjetosUnicos = (array, chave) => {
    const uniqueIds = new Set();
    return array.filter((obj) => {
        if (obj[chave] !== null && !uniqueIds.has(obj[chave])) {
            uniqueIds.add(obj[chave]);
            return true;
        }
        return false;
    });
}


export default { tratarUrlWebconferencia, inicializarValoresFormulario, formatarCadastro, verificarErrosOrdemPrecedencia, carregarImagemSrc, filtrarObjetosUnicos };