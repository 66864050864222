export const DADOS_GERAIS = 'dadosGerais';
export const INSTITUICAO_RESPONSAVEL = 'instituicaoResponsavel';
export const ENDERECO_SALA = 'enderecoSala';
export const TITULO = 'titulo';
export const DATA_HORA = 'dataHora';
export const DESCRICAO = 'descricao';
export const CARGA_HORARIA = 'cargaHoraria';
export const FOTO = 'foto';
export const PUBLICA = 'publica';
export const ACEITA_CONVIDADO = 'aceitaConvidado';
export const PUBLICOS_ALVO = 'publicosAlvo';