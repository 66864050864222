import * as yup from 'yup';
import {
    DECS,
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [DECS]: yup.array().of(
            yup.object().shape({
                id: yup.string(),
                descritor: yup.string(),
                descritor_antecessor: yup.string()
            }))
            .min(1, strings.decsMin1Descritor).required()
    });
}
