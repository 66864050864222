import { VINCULO_PERFIL_ESTADUAL } from "../common/Constants";

const temPerfil = (usuario, perfil) => {
    return usuario?.perfisRBAC?.some(p => {
        return p.identificador === perfil;
    });
};

const temPermissaoRBAC = (usuario, permissao) => {
    return usuario?.permissoesRBAC?.includes(permissao);
};

const temPermissaoVinculoEstadual = (usuario, permissao, sigla_estado) => {
    const temPerfilAdminEstadual = usuario.perfisRBAC.some(p => p.vinculo === VINCULO_PERFIL_ESTADUAL);
    const temPermissao = permissao ? temPermissaoRBAC(usuario, permissao) : true;
    
    // Caso o usuário não tenha perfil estadual, liberar a permissão para que a função temPermissaoRBAC faça o tratamento 
    if (!temPerfilAdminEstadual) {
        return temPermissao;
    }

    const estados = usuario.perfisRBAC.filter(p => p.vinculo === VINCULO_PERFIL_ESTADUAL).map(perfil => perfil.estados).flat();
    return estados.some(e => e.sigla === sigla_estado) && temPermissao;
};

export { temPerfil, temPermissaoRBAC, temPermissaoVinculoEstadual };

