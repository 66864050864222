export const DADOS_GERAIS = 'dadosGerais';
export const INSTITUICAO_RESPONSAVEL = 'instituicaoResponsavel';
export const ENDERECO_CURSO = 'enderecoCurso';
export const TITULO = 'titulo';
export const DESCRICAO = 'descricao';
export const CARGA_HORARIA = 'cargaHoraria';
export const IMAGEM = 'imagem';
export const PUBLICO = 'publico';
export const PUBLICOS_ALVO = 'publicosAlvo';
export const FORMATO_INSTRUCAO  = 'formatoInstrucao';
export const FORMATO_ATIVIDADE  = 'formatoAtividade';
export const CATEGORIA = 'categoria';
export const TITULO_BREVE = 'tituloBreve';
export const CONTATO_RESPONSAVEL = 'contatoResponsavel';
export const TURMA = 'turma';
export const VAGAS_ESGOTADAS = 'vagasEsgotadas';