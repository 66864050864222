import * as yup from 'yup';

export default function validationSchema(strings) {
    return yup.object().shape({
        'resumo': yup
            .string()
            .trim()
            .nullable()
            .required(strings.campoObrigatorio),
    });
}