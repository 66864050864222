import React, { useEffect, useRef, useState, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttButton,
    SttTranslateHook,
    SttGrid,
    SttAutocomplete,
    SttNotification,
    SttModal
} from '@stt-componentes/core';
import { connect } from 'react-redux';
import axios from 'axios';
import { getHeaders } from '../../../../request';
import {
    Formik,
    Field
} from 'formik';
import {
    setParametrosAlerta as setParametrosAlertaAction,
    setOpen as setOpenAction
} from "../../../../reducers/actions/alerta";
import { mostrarCarregando as mostrarCarregandoAction } from '../../../../reducers/actions/carregando';
import HttpStatus from 'http-status-codes';
import validationSchema from './validationSchema';

const useStyles = makeStyles(theme => ({
    form: {
        marginTop: '10px',
        marginBottom: '30px'
    },
    notificacao: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1),
    },
}));

const ListaPresencaEquipeSaude = ({ idWebconferencia, callbackCancelar, callbackRegistroEquipe, mostrarCarregando, setParametrosAlerta, setOpen }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema(strings);
    const formRef = useRef();
    const classes = useStyles();

    const [modalOpen, setModalOpen] = useState(false);
    const [equipesSaude, setEquipesSaude] = useState(false);
    const [initialValues, setInitialValues] = useState(null);

    useEffect(() => {
        mostrarCarregando(true);
        axios.get(`${global.gConfig.url_base_tele_educacao}/equipe-saude`, { headers: getHeaders() })
            .then((response) => {
                const { data } = response.data;
                if (data) {
                    setEquipesSaude(data.itens);
                    if (parseInt(data.totalRegistros) > 1) {
                        setInitialValues({
                            equipeSaude: null,
                            idWebconferencia
                        });
                        setModalOpen(true);
                        return;
                    }
                    if (parseInt(data.totalRegistros) === 1) {
                        setEquipesSaude(data.itens);
                        setInitialValues({
                            equipeSaude: data.itens[0],
                            idWebconferencia
                        });
                        setModalOpen(true);
                        return;
                    }
                }
                setEquipesSaude([]);
            })
            .catch(err => {
                console.log(err)
                setEquipesSaude([]);
            })
            .finally(() => {
                mostrarCarregando(false);
            });
    }, []);

    const enviarEquipeSaude = (dados, setSubmitting) => {
        dados.alteracao = true;

        mostrarCarregando(true, strings.salvandoMensagemEspera);
        axios.post(`${global.gConfig.url_base_tele_educacao}/acesso-webconferencia`, dados, { headers: getHeaders() })
            .then((response) => {
                const parametrosAlerta = {
                    titulo: strings.sucesso,
                    mensagem: strings.sucessoEscolhaEquipe,
                    tipo: 'success',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setOpen(false);
                                setModalOpen(false);
                                callbackRegistroEquipe();
                            }

                        }
                    ]
                };
                setParametrosAlerta(parametrosAlerta);
            })
            .catch(err => {
                const { response } = err;
                let mensagem = strings.erro;
                if (response) {
                    const { data } = response;

                    mensagem = data.message;
                    if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagem = arrMensagem.join('\n');
                        }
                    }
                }

                const parametrosAlerta = {
                    titulo: strings.erro,
                    mensagem: mensagem,
                    tipo: 'error',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setOpen(false);
                                setModalOpen(false);
                            }

                        }
                    ]
                };
                setParametrosAlerta(parametrosAlerta);
            })
            .finally(() => {
                mostrarCarregando(false);
                if (setSubmitting) {
                    setSubmitting(false);
                }
            });
    }

    return (
        <SttModal
            title={strings.escolhaEquipe}
            open={modalOpen}
            maxWidth="md"
            fullWidth={true}
            outModalClose={callbackCancelar}
            iconClose={callbackCancelar}
            children={
                <>
                    {
                        initialValues &&
                        <Formik
                            innerRef={formRef}
                            initialValues={initialValues}
                            validationSchema={schema}
                            onSubmit={(dados, { setSubmitting }) => {
                                setSubmitting(true);
                                enviarEquipeSaude(dados, setSubmitting);
                            }}
                        >
                            {
                                ({
                                    setFieldValue,
                                    handleSubmit,
                                }) => (
                                    <>
                                        <SttNotification severity="warning" className={classes.notificacao}>
                                            {strings.avisoSemEquipe}
                                        </SttNotification>
                                        <form onSubmit={handleSubmit} noValidate>
                                            <SttGrid container spacing={1}>
                                                <SttGrid item xs={12}>
                                                    <Field name={'equipeSaude'}>
                                                        {({
                                                            field: { name, value, onBlur },
                                                            meta
                                                        }) => (
                                                            <SttAutocomplete
                                                                inputprops={{
                                                                    name: name,
                                                                    required: true,
                                                                    label: strings.equipeSaudeFamilia,
                                                                    error: meta.touched && meta.error ? meta.error : undefined
                                                                }}
                                                                getOptionLabel={option => option?.nome_cidade ? (`${option.nome_cidade} - ${option.nome_equipe} - Instituição: ${option.nome_instituicao}`) : ''}
                                                                getOptionSelected={(option, val) => option?.id === val?.id}
                                                                options={equipesSaude}
                                                                value={value}
                                                                onBlur={onBlur}
                                                                onChange={(e, item) => {
                                                                    const equipe = item || null;
                                                                    setFieldValue('equipeSaude', equipe);
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                </SttGrid>
                                            </SttGrid>
                                        </form>
                                    </>
                                )
                            }
                        </Formik>
                    }
                </>
            }
            footer={
                <>
                    <SttButton variant="contained"
                        disabled={formRef.current && formRef.current.isSubmitting}
                        color="primary"
                        onClick={() => {
                            formRef.current.submitForm();
                        }}>
                        {strings.confirmar}
                    </SttButton>
                    <SttButton variant="outlined"
                        color="primary"
                        onClick={() => {
                            setModalOpen(false);
                            callbackCancelar();
                        }}>
                        {strings.fechar}
                    </SttButton>
                </>

            }
        />
    )
};

const mapDispatchToProps = (dispatch) => {
    return {
        mostrarCarregando: (open, titulo) => dispatch(mostrarCarregandoAction(open, titulo)),
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open)),
    };
};

export default connect(null, mapDispatchToProps)(ListaPresencaEquipeSaude);
