import React, { useRef, useState, useEffect, memo, Suspense, lazy, useContext } from "react";
import {
    SttContainer,
    SttLoading,
    SttHeading,
    SttCircularProgress,
    SttButton,
    SttTranslateHook,
    SttExpansionPanel,
    SttDivider
} from '@stt-componentes/core';
import axios from 'axios';
import { connect } from 'react-redux';
import HttpStatus from 'http-status-codes';
import {
    Formik
} from 'formik';
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import validationSchema from './validationSchema';
import { getHeaders } from '../../../request';
import {
    setParametrosAlerta as setParametrosAlertaAction,
    setOpen as setOpenAction
} from '../../../reducers/actions/alerta';
import { setCursoAlteracao as setCursoAlteracaoAction } from '../../../reducers/actions/cadastro/curso';
import UtilCurso from '../../../util/curso';

const DadosGerais = lazy(() => import('../../../componentes/interno/cadastro/curso/dadosGerais'));
const DetalhesCurso = lazy(() => import('../../../componentes/interno/cadastro/curso/detalhesCurso'));
const DadosDecs = lazy(() => import('../../../componentes/interno/cadastro/curso/dadosDecs'));
const Anexos = lazy(() => import('../../../componentes/interno/cadastro/curso/anexos'));

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        marginTop: theme.spacing(1)
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    header: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    botaoVoltar: {
        marginTop: theme.spacing(2)
    }
}));

const Carregando = memo((props) => {
    return (
        <SttLoading {...props} />
    )
});

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const CadastroCurso = (params) => {
    const { user, setParametrosAlerta, setOpen, cursoAlteracao, setCursoAlteracao } = params;
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema(strings, user);
    const TELE_EDUCACAO_API_BASE_URL = global.gConfig.url_base_tele_educacao;

    const formRef = useRef();
    const classes = useStyles();
    const [initialValues, setInitialValues] = useState(null);
    const [carregado, setCarregado] = useState(false);
    const history = useHistory();

    const secaoDadosGerais = useRef(null);
    const secaoDetalhesCurso = useRef(null);
    const secaoDadosDecs = useRef(null);
    const secaoDadosAnexos = useRef(null);

    const [secoesAbertas, setSecoesAbertas] = useState({
        'dadosGerais': true,
        'detalhesCurso': false,
        'dadosDecs': false,
        'dadosAnexos': false,
    });

    const abrirSecao = (secao, estado) => {
        let novoSecoesAbertas = {
            ...secoesAbertas
        };
        for (const secaoAberta in novoSecoesAbertas) {
            novoSecoesAbertas[secaoAberta] = false;
        }

        novoSecoesAbertas[secao] = estado;

        setSecoesAbertas(novoSecoesAbertas);
    }

    const verificarSecoesComErro = (validateForm) => {
        setCarregando(true);
        validateForm().then((retorno) => {
            let node = null;
            if (retorno['dadosGerais']) {
                abrirSecao('dadosGerais', true);
                node = secaoDadosGerais.current;
            } else if (retorno['detalhesCurso']) {
                abrirSecao('detalhesCurso', true);
                node = secaoDadosGerais.current;
            } else if (retorno['decs']) {
                abrirSecao('dadosDecs', true);
                node = secaoDadosDecs.current;
            } else if (retorno['dadosAnexos']) {
                abrirSecao('dadosAnexos', true);
                node = secaoDadosAnexos.current;
            }

            if (node) {
                setTimeout(() => {
                    node.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'start'
                    });
                }, 500);
            }
            setCarregando(false);
        });
    }

    //Carregando
    const [carregando, setCarregando] = useState(false);

    useEffect(() => {
        if (cursoAlteracao) {
            axios.get(`${TELE_EDUCACAO_API_BASE_URL}/curso/${cursoAlteracao}`, { headers: getHeaders() })
                .then((response) => {
                    const dados = response.data.data;
                    setInitialValues(UtilCurso.inicializarValoresFormulario(dados));
                    setCarregado(true);
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            setInitialValues(UtilCurso.inicializarValoresFormulario(null));
            setCarregado(true);
        }

    }, [cursoAlteracao]);

    const redirectPesquisaCursos = () => {
        setOpen(false);
        setCursoAlteracao(null);
        history.push("/pesquisa/curso", { state: { pesquisar: true } });
    }

    const enviarFormulario = (dados, setSubmitting) => {
        setSubmitting(true);
        axios.post(`${TELE_EDUCACAO_API_BASE_URL}/curso`, dados, { headers: { ...getHeaders(), 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                const { data } = response;

                const parametrosAlerta = {
                    titulo: strings.sucesso,
                    mensagem: data.message,
                    tipo: 'success',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: redirectPesquisaCursos
                        }
                    ],
                    onClose: redirectPesquisaCursos
                };

                setParametrosAlerta(parametrosAlerta);
            })
            .catch(err => {
                const { response } = err;
                let mensagem = strings.mensagemErroGeral;
                if (response) {
                    const { data } = response;

                    if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            if (error.message) {
                                arrMensagem.push(`- ${error.message}`);
                            }
                        });
                        if (arrMensagem.length > 0) {
                            mensagem = arrMensagem.join('\n');
                        }
                    }
                }

                const parametrosAlerta = {
                    titulo: strings.erro,
                    mensagem: mensagem,
                    tipo: 'error',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setOpen(false);
                            }
                        }
                    ]
                };

                setParametrosAlerta(parametrosAlerta);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <>
            {
                carregado ?
                    <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        validationSchema={schema}
                        onSubmit={(dados, { setSubmitting }) => {
                            let mensagem = strings.confirmacaoCadastroCurso;
                            if (cursoAlteracao) {
                                mensagem = strings.confirmacaoAlteracaoCurso;
                            }

                            if (!dados.dadosGerais.publico) {
                                mensagem = strings.confirmacaoCursoNaoPublico;
                            }

                            const parametrosAlerta = {
                                titulo: strings.atencao,
                                mensagem: mensagem,
                                tipo: 'alert',
                                open: true,
                                opcoes: [
                                    {
                                        title: strings.ok,
                                        onClick: () => {
                                            let dadosFormatados = UtilCurso.formatarCadastro(dados);
                                            if (cursoAlteracao) {
                                                dadosFormatados.append('alteracao', true);
                                            }
                                            enviarFormulario(dadosFormatados, setSubmitting);
                                            setOpen(false);
                                        }
                                    },
                                    {
                                        title: strings.cancelar,
                                        onClick: () => {
                                            setOpen(false);
                                        }
                                    }
                                ]
                            };

                            setParametrosAlerta(parametrosAlerta);
                            return true;
                        }}
                        validateOnChange={false}
                    >
                        {
                            ({
                                isSubmitting,
                                handleSubmit,
                                validateForm,
                            }) => {
                                return (
                                    <SttContainer>
                                        <form onSubmit={handleSubmit} noValidate>
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                className={classes.botaoVoltar}
                                                onClick={() => {
                                                    const parametrosAlerta = {
                                                        titulo: strings.confirmacao,
                                                        mensagem: strings.retornarTelaPesquisa,
                                                        tipo: 'alert',
                                                        open: true,
                                                        opcoes: [
                                                            {
                                                                title: strings.sim,
                                                                onClick: () => {
                                                                    setCursoAlteracao(null);
                                                                    setOpen(false);
                                                                    history.push("/pesquisa/curso", { state: { pesquisar: true } });
                                                                }

                                                            },
                                                            {
                                                                title: strings.nao,
                                                                onClick: () => {
                                                                    setOpen(false);
                                                                }

                                                            }
                                                        ]
                                                    };
                                                    setParametrosAlerta(parametrosAlerta);
                                                }}
                                                nomarginleft="true"
                                            >
                                                {strings.voltar}
                                            </SttButton>
                                            <SttHeading variant="h1" color="primary" align="center" className={classes.header}>{cursoAlteracao ? strings.alteracaoCurso : strings.cadastroCurso}</SttHeading>
                                            <SttExpansionPanel
                                                title={strings.dadosGerais}
                                                callback={estadoInterno => abrirSecao('dadosGerais', estadoInterno)}
                                                opened={secoesAbertas['dadosGerais']}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <div ref={secaoDadosGerais}></div>
                                                        <DadosGerais
                                                            carregando={carregando}
                                                            user={user}
                                                            setCarregando={setCarregando}
                                                        />
                                                    </Suspense>
                                                }
                                            />
                                            <Divider />

                                            <SttExpansionPanel
                                                title={strings.detalhesCurso}
                                                callback={estadoInterno => abrirSecao('detalhesCurso', estadoInterno)}
                                                opened={secoesAbertas['detalhesCurso']}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <div ref={secaoDetalhesCurso}></div>
                                                        <DetalhesCurso
                                                            carregando={carregando}
                                                            user={user}
                                                            setCarregando={setCarregando}
                                                        />
                                                    </Suspense>
                                                }
                                            />
                                            <Divider />

                                            <SttExpansionPanel
                                                title={strings.anexos}
                                                callback={estadoInterno => abrirSecao('dadosAnexos', estadoInterno)}
                                                opened={secoesAbertas['dadosAnexos']}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <div ref={secaoDadosAnexos}></div>
                                                        <Anexos
                                                            carregando={carregando}
                                                            user={user}
                                                            setCarregando={setCarregando}
                                                        />
                                                    </Suspense>
                                                }
                                            />
                                            <Divider />

                                            <SttExpansionPanel
                                                title={strings.dadosDecs}
                                                callback={estadoInterno => abrirSecao('dadosDecs', estadoInterno)}
                                                opened={secoesAbertas['dadosDecs']}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <div ref={secaoDadosDecs}></div>
                                                        <DadosDecs
                                                            carregando={carregando}
                                                            user={user}
                                                            setCarregando={setCarregando}
                                                        />
                                                    </Suspense>
                                                }
                                            />
                                            <Divider />

                                            <div className={classes.buttonWrapper}>
                                                <SttButton
                                                    type="submit"
                                                    variant="contained"
                                                    nomarginleft="true"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    onClick={() => verificarSecoesComErro(validateForm)}
                                                >
                                                    {strings.salvar}
                                                </SttButton>
                                            </div>
                                        </form>
                                        <SttLoading
                                            open={isSubmitting}
                                            text={strings.salvando}
                                        />

                                    </SttContainer>
                                )
                            }
                        }
                    </Formik> :
                    <div className={classes.carregando}>
                        <SttCircularProgress color="primary" />
                    </div>
            }
            <Carregando
                open={carregando}
                text={strings.carregando}
            />
        </>
    );
}


const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        cursoAlteracao: state.cursoReducer.cursoAlteracao
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCursoAlteracao: id => dispatch(setCursoAlteracaoAction(id)),
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CadastroCurso);