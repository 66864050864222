import * as yup from 'yup'

import dadosGeraisSchema from '../../../componentes/interno/cadastro/webconferencia/dadosGerais/form/validationSchema';
import dadosPalestranteSchema from '../../../componentes/interno/cadastro/webconferencia/dadosPalestrante/form/validationSchema';
import dadosAtividadeSchema from '../../../componentes/interno/cadastro/webconferencia/dadosAtividade/form/validationSchema';
import dadosDecsSchema from '../../../componentes/interno/cadastro/webconferencia/dadosDecs/form/validationSchema';

export default (strings) => {
    const schema = yup.object().shape({});

    const dadosGerais = dadosGeraisSchema(strings);
    const dadosPalestrante = dadosPalestranteSchema(strings);
    const dadosAtividade = dadosAtividadeSchema(strings);
    const dadosDecs = dadosDecsSchema(strings);

    return schema
        .concat(dadosGerais)
        .concat(dadosPalestrante)
        .concat(dadosAtividade)
        .concat(dadosDecs);
}