import * as yup from 'yup';
import {
    DATA, FUNCIONARIO
} from './fieldnames';


export default (dataWeb, strings) => {
    return yup.object().shape({
        [FUNCIONARIO]: yup
            .object()
            .nullable()
            .required(strings.campoObrigatorio),
        [DATA]: yup
            .date()
            .typeError(strings.dataInvalida)
            .min(dataWeb, strings.erroDataPassada)
            .max(new Date(), strings.erroDataFutura)
            .nullable()
            .required(strings.campoObrigatorio),
    })
};
