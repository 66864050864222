export const DETALHES_CURSO = 'detalhesCurso';
export const DATA_INICIAL_INSCRICAO = 'dataInicialInscricao';
export const DATA_FINAL_INSCRICAO = 'dataFinalInscricao';
export const DATA_INICIAL_CURSO = 'dataInicialCurso';
export const DATA_FINAL_CURSO = 'dataFinalCurso';
export const PRORROGACAO = 'prorrogacao';
export const CATEGORIAS_PUBLICOS_ALVO = 'categoriasPublicoAlvo';
export const AREA_TEMATICA = 'areaTematica';
export const CATEGORIAS_AREA_TEMATICA = 'categoriasAreaTematica';
export const IMAGEM = 'imagem';
export const DATA_PRORROGADA = 'dataProrrogada';
export const NUMERO_VAGAS = 'numeroVagas';
export const MOSTRAR_CURSO_DATA_DE = 'mostrarCursoDataDe';
export const DATA_MOSTRAR_CURSO = 'dataMostrarCurso';
export const MOSTRAR_CURSO_DATA_ATE = 'mostrarCursoDataAte';
export const DATA_OCULTAR_CURSO = 'dataOcultarCurso';
export const SUMARIO = 'sumario';