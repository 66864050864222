import TYPES from './types/curso';

const INITIAL_STATE = {
    parametrosPesquisa: {
        titulo: '',
        situacao: null,
        page: 0,
        count: 10,
        start: null,
        sort: null,
        direction: 'asc'
    },
    abaAtiva: null,
    cursoAlteracao: null
};

function cursoReducer(state, action) {
    if (!state) {
        state = INITIAL_STATE;
    }

    const { type, payload } = action;

    switch (type) {
        case TYPES.SET_PARAMETROS_PESQUISA:
            return {
                ...state,
                parametrosPesquisa: {
                    ...payload.parametros,
                    start: payload.parametros.page * payload.parametros.count
                }
            }

        case TYPES.LIMPAR_PARAMETROS_PESQUISA:
            return {
                ...state,
                parametrosPesquisa: INITIAL_STATE.parametrosPesquisa
            }

        case TYPES.SETAR_CURSO_ALTERACAO:
            return {
                ...state,
                cursoAlteracao: payload.idCurso
            }

        default:
            return state;
    }
}

export default cursoReducer;