import * as yup from 'yup';
import {
    DADOS_PALESTRANTE, 
    CBO_FAMILIA,
    CBO_OCUPACAO,
    NOME,
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [DADOS_PALESTRANTE]: yup.object().shape({
            [NOME]: yup
                .string()
                .trim()
                .max(100, strings.erroNomePalestranteMax)
                .required(strings.campoObrigatorio),
            [CBO_FAMILIA]: yup
                .object().shape({
                    id: yup.number(),
                    descritor: yup.string(),
                })
                .nullable()
                .required(strings.campoObrigatorio),
            [CBO_OCUPACAO]: yup
                .object().shape({
                    id: yup.number(),
                    descricao: yup.string(),
                })
                .nullable(),
        }).required()
    });
}
