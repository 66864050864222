import * as yup from 'yup';
import { validateBr } from 'js-brasil';

export default function validationSchema(strings) {
    return yup.object().shape({
        'cpf': yup
            .string()
            .nullable()
            .test('cpf-valido', strings.cpfInvalido, (cpf) => {
                cpf = (cpf || '').replace(/\D+/g, '');
                if (cpf.length === 0) {
                    return true;
                }
                return validateBr.cpf(cpf);
            })
            .required(strings.campoObrigatorio),
        'equipeSaude': yup
            .object()
            .nullable()
            .when('idFuncionario', {
                is: (val) => val,
                then: yup.object()
                    .shape({
                        id: yup.number(),
                        nome_equipe: yup.string(),
                        nome_cidade: yup.string(),
                        nome_instituicao: yup.string(),
                    })
                    .nullable()
                    .required(strings.campoObrigatorio),
            }),
        'telefone': yup
            .string()
            .when('idFuncionario', {
                is: (val) => !val,
                then: yup.string()
                    .test('telefone', strings.numeroCelularNoveDigitos, (telefone) => {
                        if (!telefone) {
                            return true;
                        }
                        let digitos = telefone.replace(/\D/g, '');
                        if (digitos.length < 11) { // 9 dígitos + DDD
                            return false;
                        }
                        return true;
                    })
                    .required(strings.campoObrigatorio),
            }),
        'email': yup
            .string()
            .nullable()
            .when('idFuncionario', {
                is: (val) => !val,
                then: yup.string()
                    .email(strings.emailInvalido)
                    .nullable()
                    .required(strings.campoObrigatorio),
            }),
        'nome': yup
            .string()
            .nullable()
            .when('idFuncionario', {
                is: (val) => !val,
                then: yup.string()
                    .trim()
                    .min(1)
                    .max(100)
                    .matches(/(\w.+\s).+/i, strings.informeNomeCompleto)
                    .nullable()
                    .required(strings.campoObrigatorio),
            }),
        'sexo': yup
            .object()
            .nullable()
            .when('idFuncionario', {
                is: (val) => !val,
                then: yup.object()
                    .shape({
                        id: yup.number(),
                        descricao: yup.string(),
                    })
                    .nullable()
                    .required(strings.campoObrigatorio),
            }),
    });
}