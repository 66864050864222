import * as yup from 'yup';
import {
    TEMA,
    DESCRICAO_MATERIAL,
    NOME,
    URL
} from './fieldnames';


export default (strings) => {
    return yup.object().shape({
        [NOME]: yup
            .string()
            .nullable()
            .trim()
            .min(1, strings.minimo1Caracter)
            .max(100)
            .required(strings.campoObrigatorio),
        [TEMA]: yup
            .object()
            .shape({
                id: yup.string(),
                descricao: yup.string(),
            })
            .nullable()
            .required(strings.campoObrigatorio),
        [URL]: yup
            .string()
            .nullable()
            .trim()
            .url(strings.urlInvalida)
            .required(strings.campoObrigatorio),
        [DESCRICAO_MATERIAL]: yup
            .string()
            .nullable()
            .trim()
            .required(strings.campoObrigatorio),
    })
};
