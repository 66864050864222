import * as yup from 'yup';

export default function validationSchema(strings) {
    return yup.object().shape({
        'equipeSaude': yup
            .object().shape({
                id: yup.number(),
                nome_equipe: yup.string(),
                nome_cidade: yup.string(),
                nome_instituicao: yup.string(),
            })
            .nullable()
            .required(strings.campoObrigatorio),
    });
}