import React, { useEffect, useRef, useState, lazy, Suspense, useContext } from "react";
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, FastField } from 'formik';
import {
    SttGrid,
    SttContainer,
    SttTranslateHook,
    SttDateTimePicker,
    SttCircularProgress,
    SttModal,
    SttButton
} from '@stt-componentes/core';
import axios from 'axios';
import { getHeaders } from '../../../../../request';
import { mostrarCarregando as mostrarCarregandoAction } from '../../../../../reducers/actions/carregando';
import {
    FUNCIONARIO,
    DATA,
    ITEM_BIBLIOTECA,
    ID_FUNCIONARIO
} from './fieldnames';
import schema from './validationSchema';
import {
    setParametrosAlerta as setParametrosAlertaAction,
    setOpen as setOpenAction
} from '../../../../../reducers/actions/alerta';
import httpStatus from 'http-status-codes';
const SttFuncionario = lazy(() => import('@stt-componentes/funcionario'));

const useStyles = makeStyles(theme => ({
    errorText: {
        marginTop: '-2px',
    },
    form: {
        marginTop: '10px',
        marginBottom: '30px'
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
}));

const initialValues = {
    [ID_FUNCIONARIO]: null,
    [FUNCIONARIO]: null,
    [DATA]: null,
    [ITEM_BIBLIOTECA]: null
};

const Form = ({ 
    itemBiblioteca, 
    setRegistrarAcesso, 
    setParametrosAlerta, 
    mostrarCarregando, 
    setOpen, 
    setAtualizarBusca,
    dataWebconferencia
}) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const validationSchema = schema(dataWebconferencia, strings);
    const ref = useRef();

    const [formValues, setFormValues] = useState(initialValues);

    const TELE_EDUCACAO_API_BASE_URL = global.gConfig.url_base_tele_educacao;

    useEffect(() => {
        setFormValues({ ...formValues, [ITEM_BIBLIOTECA]: itemBiblioteca });
    }, []);

    const submitForm = (data) => {
        mostrarCarregando(true);
        axios.post(`${TELE_EDUCACAO_API_BASE_URL}/webconferencia/registro-acesso`, data, { headers: getHeaders() })
            .then((response) => {
                if (response?.data?.message) {
                    setParametrosAlerta({
                        titulo: strings.sucesso,
                        mensagem: response.data.message,
                        tipo: 'success',
                        open: true,
                        opcoes: [
                            {
                                title: strings.ok,
                                onClick: () => {
                                    setOpen(false);
                                    setRegistrarAcesso(false);
                                    setAtualizarBusca(true);
                                }
                            }
                        ]
                    });
                }
            })
            .catch(err => {
                const { response } = err;
                let mensagemAlertaErro = strings.mensagemErroGeral;

                if (response && (response.status === httpStatus.BAD_REQUEST)) {
                    const { data } = response;
                    let arrMensagem = [];
                    data.errors.forEach(error => {
                        arrMensagem.push(`- ${error.message}`);
                    });
                    mensagemAlertaErro = arrMensagem.join('\n');
                }

                const parametrosAlertaErro = {
                    titulo: strings.erro,
                    mensagem: mensagemAlertaErro,
                    tipo: 'error',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setOpen(false);
                            }
                        }
                    ]
                };

                setParametrosAlerta(parametrosAlertaErro);
            })
            .finally(() => {
                mostrarCarregando(false);
            });
    }

    return (
        <SttModal
            title={strings.registrarAcesso}
            open={true}
            maxWidth="md"
            outModalClose={() => setRegistrarAcesso(false)}
            iconClose={() => setRegistrarAcesso(false)}
            fullWidth={true}
            children={
                <Formik
                    innerRef={ref}
                    enableReinitialize
                    initialValues={formValues}
                    validationSchema={validationSchema}
                    onSubmit={(data) => {
                        const payload = {
                            [ITEM_BIBLIOTECA]: data[ITEM_BIBLIOTECA],
                            [ID_FUNCIONARIO]: data[FUNCIONARIO].id,
                            [DATA]: data[DATA]
                        }
                        let mensagem = strings.confirmacaoRegistroAcesso;
                        setParametrosAlerta({
                            titulo: strings.atencao,
                            mensagem: mensagem,
                            open: true,
                            tipo: 'alert',
                            opcoes: [
                                {
                                    title: strings.sim,
                                    onClick: () => {
                                        submitForm(payload);
                                    }
                                },
                                {
                                    title: strings.nao,
                                    onClick: () => {
                                        setOpen(false);
                                    }
                                }
                            ],
                            onClose: () => {
                                setOpen(false);
                            }
                        });
                    }}
                    validateOnChange={false}
                >
                    {
                        ({
                            values,
                            ...formikEvents
                        }) => {
                            return (
                                <SttContainer>
                                    <form className={classes.form}>
                                        <SttGrid container spacing={3}>
                                            <SttGrid item xs={12} sm={7}>
                                                <FastField name={FUNCIONARIO}>
                                                    {() => (
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <SttFuncionario
                                                                fieldName={FUNCIONARIO}
                                                                headers={getHeaders()}
                                                                strings={strings}
                                                                obrigatorio
                                                                formExterno={{
                                                                    funcionario: values[FUNCIONARIO],
                                                                    ...formikEvents
                                                                }}
                                                            />
                                                        </Suspense>
                                                    )}
                                                </FastField>
                                            </SttGrid>
                                            <SttGrid item xs={12} sm={5}>
                                                <FastField name={DATA}>
                                                    {({
                                                        field: { value },
                                                        form: { setFieldValue, setFieldError, setFieldTouched },
                                                        meta
                                                    }) => {
                                                        return (
                                                            <SttDateTimePicker
                                                                required={true}
                                                                value={value}
                                                                label={strings.dataHora}
                                                                minDate={dataWebconferencia}
                                                                onChange={item => {
                                                                    setFieldValue(DATA, item || null);
                                                                }}
                                                                error={meta.touched && meta.error ? true : false}
                                                                onError={error => {
                                                                    setFieldError(DATA, meta.error || error);
                                                                }}
                                                                onBlur={() => {
                                                                    setFieldTouched(DATA, true);
                                                                }}
                                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                                            />
                                                        );
                                                    }}
                                                </FastField>
                                            </SttGrid>
                                        </SttGrid>
                                    </form>
                                </SttContainer>
                            )
                        }
                    }
                </Formik>
            }
            footer={
                <div>
                    <SttButton
                        variant="contained"
                        color="primary"
                        nomarginleft="true"
                        disabled={ref?.current?.isSubmitting}
                        onClick={() => {
                            if (ref?.current) {
                                ref.current.handleSubmit();
                            }
                        }}
                    >
                        {strings.registrar}
                    </SttButton>
                    <SttButton variant="contained"
                        color="secondary"
                        onClick={() => setRegistrarAcesso(false)}>
                        {strings.fechar}
                    </SttButton>
                </div>
            }
        />
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open)),
        mostrarCarregando: (open, titulo) => dispatch(mostrarCarregandoAction(open, titulo))
    };
};

export default connect(null, mapDispatchToProps)(Form);