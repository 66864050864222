import React, { useState, useRef, lazy, Suspense, memo, useEffect, useContext } from 'react';
import {
    SttTabs,
    SttCircularProgress,
    SttTranslateHook
} from '@stt-componentes/core';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import {
    setAbaAtiva as setAbaAtivaAction,
    limparParametrosPesquisa as limparParametrosPesquisaAction
} from '../../../reducers/actions/cursos';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(1.5)
    },
}));

const Pesquisa = lazy(() => import('../../../componentes/interno/pesquisa/curso'));

const PesquisaSuspense = memo((props) => {
    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <Pesquisa {...props} />
        </Suspense>
    )
});

const PesquisaCursos = ({ abaAtiva, setAbaAtiva, limparParametrosPesquisa }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    const [abas, setAbas] = useState([]);
    const stateRef = useRef();

    stateRef.current = abas;

    useEffect(() => {
        setAbas([
            {
                titulo: strings.pesquisa,
                conteudo: PesquisaSuspense,
                key: 'PesquisaSuspense',
                permanente: true
            }
        ]);
    }, []);

    return (
        <SttTabs abas={abas}
            canClose={true}
            className={classes.wrapper}
            handleChangeAbaAtiva={(aba) => {
                setAbaAtiva(aba);
                limparParametrosPesquisa();
            }}
            abaAtiva={abaAtiva}
            permanente={true}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        abaAtiva: state.cursoReducer.abaAtiva
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAbaAtiva: id => dispatch(setAbaAtivaAction(id)),
        limparParametrosPesquisa: () => dispatch(limparParametrosPesquisaAction()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PesquisaCursos);