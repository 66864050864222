import React, { useRef, useState, useEffect, memo, Suspense, lazy, useContext } from "react";
import {
    SttContainer,
    SttLoading,
    SttHeading,
    SttCircularProgress,
    SttButton,
    SttTranslateHook,
    SttExpansionPanel,
    SttDivider
} from '@stt-componentes/core';
import axios from 'axios';
import { connect } from 'react-redux';
import HttpStatus from 'http-status-codes';
import {
    Formik
} from 'formik';
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import validationSchema from './validationSchema';
import { getHeaders } from '../../../request';
import {
    setWebconferenciaAlteracao as setWebconferenciaAlteracaoAction
} from '../../../reducers/actions/cadastro/webconferencia/index';
import {
    setPesquisarEventosAndamento as setPesquisarEventosAndamentoAction
} from '../../../reducers/actions/webconferencias';
import {
    setParametrosAlerta as setParametrosAlertaAction,
    setOpen as setOpenAction
} from '../../../reducers/actions/alerta';

import UtilWebconferencia from '../../../util/webconferencia';

const DadosGerais = lazy(() => import('../../../componentes/interno/cadastro/webconferencia/dadosGerais'));
const DadosPalestrante = lazy(() => import('../../../componentes/interno/cadastro/webconferencia/dadosPalestrante'));
const DadosDecs = lazy(() => import('../../../componentes/interno/cadastro/webconferencia/dadosDecs'));
const DadosAtividade = lazy(() => import('../../../componentes/interno/cadastro/webconferencia/dadosAtividade'));

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        marginTop: theme.spacing(1)
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    header: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    botaoVoltar: {
        marginTop: theme.spacing(2)
    }
}));

const Carregando = memo((props) => {
    return (
        <SttLoading {...props} />
    )
});

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const CadastroWebconferencia = (params) => {
    const { user, setParametrosAlerta, setOpen, webconferenciaAlteracao, setWebconferenciaAlteracao, setPesquisarEventosAndamento } = params;
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema(strings, user);
    const TELE_EDUCACAO_API_BASE_URL = global.gConfig.url_base_tele_educacao;

    const formRef = useRef();
    const classes = useStyles();
    const [initialValues, setInitialValues] = useState(null);
    const [carregado, setCarregado] = useState(false);
    const history = useHistory();

    const secaoDadosGerais = useRef(null);
    const secaoDadosPalestrante = useRef(null);
    const secaoDadosAtividades = useRef(null);
    const secaoDadosDecs = useRef(null);

    const [secoesAbertas, setSecoesAbertas] = useState({
        'dadosGerais': true,
        'dadosPalestrante': false,
        'dadosAtividade': false,
        'dadosDecs': false,
    });

    const abrirSecao = (secao, estado) => {
        let novoSecoesAbertas = {
            ...secoesAbertas
        };
        for (const secaoAberta in novoSecoesAbertas) {
            novoSecoesAbertas[secaoAberta] = false;
        }

        novoSecoesAbertas[secao] = estado;

        setSecoesAbertas(novoSecoesAbertas);
    }

    const verificarSecoesComErro = (validateForm) => {
        setCarregando(true);
        validateForm().then((retorno) => {
            let node = null;
            if (retorno['dadosGerais']) {
                abrirSecao('dadosGerais', true);
                node = secaoDadosGerais.current;
            } else if (retorno['dadosPalestrante']) {
                abrirSecao('dadosPalestrante', true);
                node = secaoDadosPalestrante.current;
            } else if (retorno['dadosAtividade']) {
                abrirSecao('dadosAtividade', true);
                node = secaoDadosAtividades.current;
            } else if (retorno['decs']) {
                abrirSecao('dadosDecs', true);
                node = secaoDadosDecs.current;
            }

            if (node) {
                setTimeout(() => {
                    node.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'start'
                    });
                }, 500);
            }
            setCarregando(false);
        });
    }

    //Carregando
    const [carregando, setCarregando] = useState(false);

    useEffect(() => {
        if (webconferenciaAlteracao) {
            axios
                .get(`${TELE_EDUCACAO_API_BASE_URL}/webconferencia/${webconferenciaAlteracao}`, { headers: getHeaders() })
                .then((response) => {
                    const dados = response.data.data;
                    setInitialValues(UtilWebconferencia.inicializarValoresFormulario(dados));
                    setCarregado(true);
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            setInitialValues(UtilWebconferencia.inicializarValoresFormulario(null));
            setCarregado(true);
        }
        
    }, [webconferenciaAlteracao]);

    const redirectPesquisaWebconferencias = () => {
        setOpen(false);
        setWebconferenciaAlteracao(null);
        setPesquisarEventosAndamento(true);
        history.push("/webconferencia", { state: { pesquisar: true } });
    }

    const enviarFormulario = (dados, setSubmitting) => {
        setSubmitting(true);
        axios.post(`${TELE_EDUCACAO_API_BASE_URL}/webconferencia`, dados, { headers: { ...getHeaders(), 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                const { data } = response;

                const parametrosAlerta = {
                    titulo: strings.sucesso,
                    mensagem: data.message,
                    tipo: 'success',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: redirectPesquisaWebconferencias
                        }
                    ],
                    onClose: redirectPesquisaWebconferencias
                };

                setParametrosAlerta(parametrosAlerta);
            })
            .catch(err => {
                const { response } = err;
                let mensagem = strings.mensagemErroGeral;
                if (response) {
                    const { data } = response;

                    if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            if(error.message) {
                                arrMensagem.push(`- ${error.message}`);
                            }
                        });
                        if (arrMensagem.length > 0) {
                            mensagem = arrMensagem.join('\n');
                        }
                    }
                }

                const parametrosAlerta = {
                    titulo: strings.erro,
                    mensagem: mensagem,
                    tipo: 'error',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setOpen(false);
                            }
                        }
                    ]
                };

                setParametrosAlerta(parametrosAlerta);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <>
            {
                carregado ?
                    <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={schema}
                        onSubmit={(dados, { setSubmitting }) => {
                            let mensagem = strings.confirmacaoCadastroWebconferencia;
                            if (webconferenciaAlteracao) {
                                mensagem = strings.confirmacaoAlteracaoWebconferencia;
                            }

                            if(!dados.dadosGerais.publica) {
                                mensagem = strings.confirmacaoWebconferenciaNaoPublica;
                            }

                            const parametrosAlerta = {
                                titulo: strings.atencao,
                                mensagem: mensagem,
                                tipo: 'alert',
                                open: true,
                                opcoes: [
                                    {
                                        title: strings.ok,
                                        onClick: () => {
                                            let dadosFormatados = UtilWebconferencia.formatarCadastro(dados);
                                            if (webconferenciaAlteracao) {
                                                dadosFormatados.append('alteracao', true);
                                            }
                                            enviarFormulario(dadosFormatados, setSubmitting);
                                            setOpen(false);
                                        }
                                    },
                                    {
                                        title: strings.cancelar,
                                        onClick: () => {
                                            setOpen(false);
                                        }
                                    }
                                ]
                            };

                            setParametrosAlerta(parametrosAlerta);
                            return true;
                        }}
                        validateOnChange={false}
                    >
                        {
                            ({
                                isSubmitting,
                                handleSubmit,
                                validateForm,
                                values
                            }) => {
                                return (
                                    <SttContainer>
                                        <form onSubmit={handleSubmit} noValidate>
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                className={classes.botaoVoltar}
                                                onClick={() => {
                                                    const parametrosAlerta = {
                                                        titulo: strings.confirmacao,
                                                        mensagem: strings.retornarTelaPesquisa,
                                                        tipo: 'alert',
                                                        open: true,
                                                        opcoes: [
                                                            {
                                                                title: strings.sim,
                                                                onClick: () => {
                                                                    setPesquisarEventosAndamento(true);
                                                                    setWebconferenciaAlteracao(null);
                                                                    setOpen(false);
                                                                    history.push("/webconferencia", { state: { pesquisar: true } });
                                                                }

                                                            },
                                                            {
                                                                title: strings.nao,
                                                                onClick: () => {
                                                                    setOpen(false);
                                                                }

                                                            }
                                                        ]
                                                    };
                                                    setParametrosAlerta(parametrosAlerta);
                                                }}
                                                nomarginleft="true"
                                            >
                                                {strings.voltar}
                                            </SttButton>

                                            <SttHeading variant="h1" color="primary" align="center" className={classes.header}>{webconferenciaAlteracao ? strings.alteracaoWebconferencia : strings.cadastroWebconferencia}</SttHeading>
                                            <SttExpansionPanel
                                                title={strings.dadosGerais}
                                                callback={estadoInterno => abrirSecao('dadosGerais', estadoInterno)}
                                                opened={secoesAbertas['dadosGerais']}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <div ref={secaoDadosGerais}></div>
                                                        <DadosGerais
                                                            carregando={carregando}
                                                            user={user}
                                                            setCarregando={setCarregando}
                                                        />
                                                    </Suspense>
                                                }
                                            />
                                            <Divider />

                                            <SttExpansionPanel
                                                title={strings.dadosPalestrante}
                                                callback={estadoInterno => abrirSecao('dadosPalestrante', estadoInterno)}
                                                opened={secoesAbertas['dadosPalestrante']}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <div ref={secaoDadosPalestrante}></div>
                                                        <DadosPalestrante
                                                            carregando={carregando}
                                                            user={user}
                                                            setCarregando={setCarregando}
                                                        />
                                                    </Suspense>
                                                }
                                            />
                                            <Divider />

                                            <SttExpansionPanel
                                                title={strings.dadosAtividade}
                                                callback={estadoInterno => abrirSecao('dadosAtividade', estadoInterno)}
                                                opened={secoesAbertas['dadosAtividade']}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <div ref={secaoDadosAtividades}></div>
                                                        <DadosAtividade
                                                            carregando={carregando}
                                                            user={user}
                                                            setCarregando={setCarregando}
                                                        />
                                                    </Suspense>
                                                }
                                            />
                                            <Divider />

                                            <SttExpansionPanel
                                                title={strings.dadosDecs}
                                                callback={estadoInterno => abrirSecao('dadosDecs', estadoInterno)}
                                                opened={secoesAbertas['dadosDecs']}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <div ref={secaoDadosDecs}></div>
                                                        <DadosDecs
                                                            carregando={carregando}
                                                            user={user}
                                                            setCarregando={setCarregando}
                                                        />
                                                    </Suspense>
                                                }
                                            />
                                            <Divider />

                                            <div className={classes.buttonWrapper}>
                                                <SttButton
                                                    type="submit"
                                                    variant="contained"
                                                    nomarginleft="true"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    onClick={() => verificarSecoesComErro(validateForm)}
                                                >
                                                    {strings.salvar}
                                                </SttButton>
                                            </div>
                                        </form>
                                        <SttLoading
                                            open={isSubmitting}
                                            text={strings.salvando}
                                        />

                                    </SttContainer>
                                )
                            }
                        }
                    </Formik> :
                    <div className={classes.carregando}>
                        <SttCircularProgress color="primary" />
                    </div>
            }
            <Carregando
                open={carregando}
                text={strings.carregando}
            />
        </>
    );
}


const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        webconferenciaAlteracao: state.webconferenciaReducer.webconferenciaAlteracao
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setWebconferenciaAlteracao: id => dispatch(setWebconferenciaAlteracaoAction(id)),
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setPesquisarEventosAndamento: (pesquisar) => dispatch(setPesquisarEventosAndamentoAction(pesquisar)),
        setOpen: (open) => dispatch(setOpenAction(open)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CadastroWebconferencia);