import React from 'react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createRoot } from 'react-dom/client';

const initializeApp = (config) => {
    /* eslint-disable */
    global.gConfig = config;

    const domNode = document.getElementById('root');
    const root = createRoot(domNode);
    
    root.render(<App modulo={'teleEducacao'} currentLanguage={'ptbr'} />);
}

const verificarBaseAlternativa = (config) => {
    let subpaths = window.location.pathname;
    if (subpaths && config.layoutsAlternativos) {
        subpaths = subpaths.split('/');

        let layoutAlternativo = null;
        subpaths.forEach(sp => {
            if (config.layoutsAlternativos.includes(sp)) {
                layoutAlternativo = sp;
                return true;
            }
        });

        if (layoutAlternativo) {
            fetch(`${process.env.PUBLIC_URL}/config/layoutsAlternativos/${layoutAlternativo}/config.stt-${config.config_id}.json`, {
                headers: { 'Content-Type': 'application/json' }
            })
                .then((response) => response.json())
                .then((config) => initializeApp(config));
            return;
        }
    }
    initializeApp(config);
}

Promise.all([
    fetch(`${process.env.PUBLIC_URL}/config/changelog.json`).then(response => response.json()),
    fetch(`${process.env.PUBLIC_URL}/config/config.json`).then(response => response.json())
])
    .then(([changelog, config]) => verificarBaseAlternativa({ ...config, ...changelog }))
    .catch(error => { console.error("Erro ao buscar os arquivos de configurações do frontend", error); });

serviceWorker.unregister();
