import * as yup from 'yup';
import {
    CATEGORIAS_AREA_TEMATICA,
    CATEGORIAS_PUBLICOS_ALVO,
    DATA_FINAL_CURSO,
    DATA_FINAL_INSCRICAO,
    DATA_INICIAL_CURSO,
    DATA_INICIAL_INSCRICAO,
    DATA_MOSTRAR_CURSO,
    DATA_OCULTAR_CURSO,
    DETALHES_CURSO,
    MOSTRAR_CURSO_DATA_ATE,
    MOSTRAR_CURSO_DATA_DE,
    NUMERO_VAGAS,
} from './fieldNames';
import { CONSTANTE_MOSTRAR_CURSO_DATA_ATE_APOS_FINAL_CURSO, CONSTANTE_MOSTRAR_CURSO_DATA_DE_ANTES_INICIO } from '../../../../../../common/Constants';
import moment from 'moment';

export default (strings) => {
    return yup.object().shape({
        [DETALHES_CURSO]: yup.object().shape({
            [NUMERO_VAGAS]: yup.number()
                .nullable()
                .required(strings.campoObrigatorio),
            [DATA_INICIAL_INSCRICAO]: yup
                .date()
                .typeError(strings.dataInvalida)
                .nullable()
                .when(DATA_INICIAL_CURSO, {
                    is: (val) => val,
                    then: yup.date()
                        .max(yup.ref(DATA_INICIAL_CURSO), strings.dataInscricaoAnteriorDataInicial)
                })
                .required(strings.campoObrigatorio),
            [DATA_FINAL_INSCRICAO]: yup
                .date()
                .typeError(strings.dataInvalida)
                .min(yup.ref(DATA_INICIAL_INSCRICAO), strings.dataFinalAnteriorDataInicial)
                .when(DATA_FINAL_CURSO, {
                    is: (val) => val,
                    then: yup.date()
                        .max(yup.ref(DATA_FINAL_CURSO), strings.dataFinalInscricaoAntesDataFinal)
                })
                .nullable()
                .required(strings.campoObrigatorio),
            [DATA_INICIAL_CURSO]: yup
                .date()
                .typeError(strings.dataInvalida)
                .nullable()
                .required(strings.campoObrigatorio),
            [DATA_FINAL_CURSO]: yup
                .date()
                .typeError(strings.dataInvalida)
                .min(yup.ref(DATA_INICIAL_CURSO), strings.dataFinalAnteriorDataInicial)
                .nullable()
                .required(strings.campoObrigatorio),
            [CATEGORIAS_PUBLICOS_ALVO]: yup.array().of(
                yup.object().shape({
                    id: yup.number(),
                    descricao: yup.string()
                }).required()
            ).min(1, strings.erroSelecaoPublicoAlvo)
                .required(),
            [CATEGORIAS_AREA_TEMATICA]: yup.array().of(
                yup.object().shape({
                    id: yup.number(),
                    descricao: yup.string()
                }).required()
            ).min(1, strings.categoriaObrigatoria)
                .required(),
            [MOSTRAR_CURSO_DATA_DE]: yup.object()
                .nullable()
                .required(strings.campoObrigatorio),
            [MOSTRAR_CURSO_DATA_ATE]: yup.object()
                .nullable()
                .required(strings.campoObrigatorio),
            [DATA_MOSTRAR_CURSO]: yup
                .date()
                .when(MOSTRAR_CURSO_DATA_DE, {
                    is: (val) => val && val.identificador === CONSTANTE_MOSTRAR_CURSO_DATA_DE_ANTES_INICIO,
                    then: yup.date()
                        .max(yup.ref(DATA_INICIAL_CURSO), strings.dataMostrarCursoAnteriorDataInicial)
                        .required(strings.campoObrigatorio)
                })
                .typeError(strings.dataInvalida)
                .nullable(),
            [DATA_OCULTAR_CURSO]: yup
                .date()
                .typeError(strings.dataInvalida)
                .nullable()
                .when(MOSTRAR_CURSO_DATA_ATE, {
                    is: (val) => val && val.identificador === CONSTANTE_MOSTRAR_CURSO_DATA_ATE_APOS_FINAL_CURSO,
                    then: yup.date()
                        .test('data-ocultar-curso', strings.dataOcultarCursoPosteriorDataFinal, function (value) {
                            if (!value) {
                                return true;
                            }
                            const parent = this.parent;
                            let dataOcultarCurso = moment(value);

                            if (parent?.prorrogacao && parent.dataFinalCurso) {
                                let dataFinalCursoMoment = moment(parent.dataFinalCurso);
                                dataFinalCursoMoment.add(parent.prorrogacao, 'days');

                                if (dataFinalCursoMoment.isAfter(dataOcultarCurso)) {
                                    return false;
                                }
                            }

                            return true;
                        })
                        .required(strings.campoObrigatorio)
                })
        }).required()
    });
}
