import * as yup from 'yup';

export default function validationSchema(strings) {
    return yup.object().shape({
        'pergunta': yup
            .string()
            .trim()
            .nullable()
            .required(strings.campoObrigatorio),
        'resposta': yup
            .string()
            .trim()
            .nullable(),
    });
}