import React, { Fragment, useEffect, useRef, useContext } from "react";
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Field } from 'formik';
import {
    SttGrid,
    SttContainer,
    SttTranslateHook,
    SttHeading,
    SttNumberInput,
    SttLabel
} from '@stt-componentes/core';
import axios from 'axios';
import { getHeaders } from '../../../../../request';
import { mostrarCarregando as mostrarCarregandoAction } from '../../../../../reducers/actions/carregando';
import {
    AVALIACAO, BOM, MUITO_BOM, REGULAR, RUIM
} from './fieldnames';
import schema from './validationSchema';
import {
    setParametrosAlerta as setParametrosAlertaAction,
    setOpen as setOpenAction
} from '../../../../../reducers/actions/alerta';
import httpStatus from 'http-status-codes';

const useStyles = makeStyles(theme => ({
    errorText: {
        marginTop: '-2px',
    },
    form: {
        marginTop: '10px',
        marginBottom: '30px'
    },
    grid: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
    },
    heading: {
        marginTop: theme.spacing(1),
    },
    buttons: {
        display: 'flex',
        marginTop: theme.spacing(1),
        justifyContent: 'flex-start',
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
}));

const useStylesHeading = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2)
    }
}));

const FormOpcoes = ({ setModalAvaliacao, setRecarregarWebconferencia, webconferenciaAvaliacao, mostrarCarregando, setRef, avaliacaoEdicao, setParametrosAlerta, setOpen }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const classesHeading = useStylesHeading();
    const validationSchema = schema(strings);
    const ref = useRef();

    const initialValues = {
        [AVALIACAO]: [
            {
                titulo: strings.tituloAvaliacaoClassificacao,
                muitoBom: 0,
                bom: 0,
                regular: 0,
                ruim: 0
            },
            {
                titulo: strings.tituloAvaliacaoRelevancia,
                muitoBom: 0,
                bom: 0,
                regular: 0,
                ruim: 0
            },
            {
                titulo: strings.tituloAvaliacaoClarezaObjetividade,
                muitoBom: 0,
                bom: 0,
                regular: 0,
                ruim: 0
            },
        ]
    };

    const TELE_EDUCACAO_API_BASE_URL = global.gConfig.url_base_tele_educacao;

    useEffect(() => {
        if (avaliacaoEdicao && ref?.current) {
            ref.current.resetForm({
                values: {
                    [AVALIACAO]: [
                        {
                            titulo: strings.tituloAvaliacaoClassificacao,
                            muitoBom: avaliacaoEdicao[0].muitoBom,
                            bom: avaliacaoEdicao[0].bom,
                            regular: avaliacaoEdicao[0].regular,
                            ruim: avaliacaoEdicao[0].ruim
                        },
                        {
                            titulo: strings.tituloAvaliacaoRelevancia,
                            muitoBom: avaliacaoEdicao[1].muitoBom,
                            bom: avaliacaoEdicao[1].bom,
                            regular: avaliacaoEdicao[1].regular,
                            ruim: avaliacaoEdicao[1].ruim
                        },
                        {
                            titulo: strings.tituloAvaliacaoClarezaObjetividade,
                            muitoBom: avaliacaoEdicao[2].muitoBom,
                            bom: avaliacaoEdicao[2].bom,
                            regular: avaliacaoEdicao[2].regular,
                            ruim: avaliacaoEdicao[2].ruim
                        },
                    ]
                }
            });
        }
    }, [ref, avaliacaoEdicao]);

    useEffect(() => {
        if (ref?.current) {
            setRef(ref);
        }
    }, [ref]);

    const submitForm = (data) => {
        mostrarCarregando(true);
        axios.post(`${TELE_EDUCACAO_API_BASE_URL}/avaliar`, data, { headers: getHeaders() })
            .then((response) => {
                if (response?.data?.message) {
                    setParametrosAlerta({
                        titulo: strings.sucesso,
                        mensagem: response.data.message,
                        tipo: 'success',
                        open: true,
                        opcoes: [
                            {
                                title: strings.ok,
                                onClick: () => {
                                    setOpen(false);
                                    setModalAvaliacao(false);
                                    setRecarregarWebconferencia(true);
                                }
                            }
                        ]
                    });
                }
            })
            .catch(err => {
                const { response } = err;
                mostrarCarregando(false);
                let mensagemAlertaErro = strings.mensagemErroGeral;

                if (response && (response.status === httpStatus.BAD_REQUEST)) {
                    const { data } = response;
                    let arrMensagem = [];
                    data.errors.forEach(error => {
                        arrMensagem.push(`- ${error.message}`);
                    });
                    mensagemAlertaErro = arrMensagem.join('\n');
                }

                const parametrosAlertaErro = {
                    titulo: strings.erro,
                    mensagem: mensagemAlertaErro,
                    tipo: 'error',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setOpen(false);
                            }
                        }
                    ]
                };

                setParametrosAlerta(parametrosAlertaErro);
            })
            .finally(() => {
                mostrarCarregando(false);
            });
    }

    return (
        <Formik
            innerRef={ref}
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(data) => {
                data.haAvaliacao = data.avaliacao.find(avaliacao =>
                    parseInt(avaliacao.muitoBom) > 0 ||
                    parseInt(avaliacao.bom) > 0 ||
                    parseInt(avaliacao.regular) > 0 ||
                    parseInt(avaliacao.ruim) > 0
                ) != null;

                data.alteracao = avaliacaoEdicao != null;
                data.idWebconferencia = webconferenciaAvaliacao;

                let mensagem = strings.confirmacaoAvaliacao;
                if (data.alteracao) {
                    mensagem = strings.confirmacaoAlteracaoAvaliacao;
                }

                // Verifica se o usuário vai fazer uma avaliação pela primeira vez 
                // mas todos os resultados são iguais a 0
                if (!data.alteracao && !data.haAvaliacao) {
                    setParametrosAlerta({
                        titulo: strings.atencao,
                        mensagem: strings.mensagemSemAvaliacao,
                        open: true,
                        tipo: 'alert',
                        opcoes: [
                            {
                                title: strings.ok,
                                onClick: () => {
                                    setOpen(false);
                                }
                            }
                        ],
                        onClose: () => {
                            setOpen(false);
                        }
                    });
                }

                setParametrosAlerta({
                    titulo: strings.atencao,
                    mensagem: mensagem,
                    open: true,
                    tipo: 'alert',
                    opcoes: [
                        {
                            title: strings.sim,
                            onClick: () => {
                                submitForm(data);
                            }
                        },
                        {
                            title: strings.nao,
                            onClick: () => {
                                setOpen(false);
                            }
                        }
                    ],
                    onClose: () => {
                        setOpen(false);
                    }
                });
            }}
            validateOnChange={false}
        >
            {
                ({
                    values
                }) => {
                    return (
                        <SttContainer>
                            <form className={classes.form}>
                                <SttGrid container className={classes.grid} spacing={2}>
                                    {
                                        values.avaliacao && values.avaliacao.map((avaliacao, indiceAvaliacao) => (
                                            <Fragment key={indiceAvaliacao}>
                                                <SttGrid item xs={12}>
                                                    <SttHeading variant="h3" color="primary" className={classesHeading.root}>{avaliacao.titulo}</SttHeading>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={6} md={3}>
                                                    <Field name={`${AVALIACAO}.[${indiceAvaliacao}].${MUITO_BOM}`}>
                                                        {({
                                                            field,
                                                            meta
                                                        }) => (
                                                            <div>
                                                                <SttLabel variant="body2" color="primary" style={{ wordBreak: 'break-word' }}>{strings.avaliacaoMuitoBom}</SttLabel>
                                                                <SttNumberInput
                                                                    {...field}
                                                                    label={strings.ine}
                                                                    inputProps={{
                                                                        maxLength: 2
                                                                    }}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                />
                                                            </div>
                                                        )}
                                                    </Field>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={6} md={3}>
                                                    <Field name={`${AVALIACAO}.[${indiceAvaliacao}].${BOM}`}>
                                                        {({
                                                            field,
                                                            meta
                                                        }) => (
                                                            <div>
                                                                <SttLabel variant="body2" color="primary" style={{ wordBreak: 'break-word' }}>{strings.avaliacaoBom}</SttLabel>
                                                                <SttNumberInput
                                                                    {...field}
                                                                    label={strings.ine}
                                                                    inputProps={{
                                                                        maxLength: 2
                                                                    }}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                />
                                                            </div>
                                                        )}
                                                    </Field>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={6} md={3}>
                                                    <Field name={`${AVALIACAO}.[${indiceAvaliacao}].${REGULAR}`}>
                                                        {({
                                                            field,
                                                            meta
                                                        }) => (
                                                            <div>
                                                                <SttLabel variant="body2" color="primary" style={{ wordBreak: 'break-word' }}>{strings.avaliacaoRegular}</SttLabel>
                                                                <SttNumberInput
                                                                    {...field}
                                                                    label={strings.ine}
                                                                    inputProps={{
                                                                        maxLength: 2
                                                                    }}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                />
                                                            </div>
                                                        )}
                                                    </Field>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={6} md={3}>
                                                    <Field name={`${AVALIACAO}.[${indiceAvaliacao}].${RUIM}`}>
                                                        {({
                                                            field,
                                                            meta
                                                        }) => (
                                                            <div>
                                                                <SttLabel variant="body2" color="primary" style={{ wordBreak: 'break-word' }}>{strings.avaliacaoRuim}</SttLabel>
                                                                <SttNumberInput
                                                                    {...field}
                                                                    label={strings.ine}
                                                                    inputProps={{
                                                                        maxLength: 2
                                                                    }}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                />
                                                            </div>
                                                        )}
                                                    </Field>
                                                </SttGrid>
                                            </Fragment>
                                        ))
                                    }
                                    <br />
                                </SttGrid>
                            </form>
                        </SttContainer>
                    )
                }
            }
        </Formik>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open)),
        mostrarCarregando: (open, titulo) => dispatch(mostrarCarregandoAction(open, titulo))
    };
};

export default connect(null, mapDispatchToProps)(FormOpcoes);