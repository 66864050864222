
import TYPES from '../types/curso';

export const setAbaAtiva = (abaAtiva) => {
    return {
        type: TYPES.SET_ABA_ATIVA,
        payload: { abaAtiva }
    }
}

export const setParametrosPesquisa = (parametros) => {
    return {
        type: TYPES.SET_PARAMETROS_PESQUISA,
        payload: { parametros }
    };
}

export const limparParametrosPesquisa = () => {
    return {
        type: TYPES.LIMPAR_PARAMETROS_PESQUISA
    };
}