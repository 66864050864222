import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableSortLabel,
    SttTablePagination,
    SttCircularProgress,
    SttHidden,
    SttText,
    SttDeleteButton,
    SttTranslateHook
} from '@stt-componentes/core';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import axios from 'axios';
import { getHeaders } from '../../../request';
import Chip from '@material-ui/core/Chip';
import { connect } from 'react-redux';
import {
    setParametrosAlerta as setParametrosAlertaAction,
    setOpen as setOpenAction
} from "../../../reducers/actions/alerta";
import HttpStatus from 'http-status-codes';
import { mostrarCarregando as mostrarCarregandoAction } from '../../../reducers/actions/carregando';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(2)
    },
    table: {
        overflow: 'auto'
    },
    notification: {
        display: 'flex',
        flexDirection: 'column'
    },
    alert: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    chip: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        height: 'auto',
        '& span': {
            whiteSpace: 'pre-wrap'
        }
    }
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        NOME: 'nome',
        MUNICIPIO: 'nome_cidade',
        EQUIPE: 'nome_equipe',
        INSTITUICAO: 'nome_instituicao'
    }
};

const ListaPresencaPesquisa = ({ participantes, setParticipantes, user, idWebconferencia, buscar, setBuscar, setParametrosAlerta, setOpen, mostrarCarregando, permiteConvidado }) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const [page, setPage] = useState(global.gConfig.pagination.start);
    const [count, setCount] = useState(global.gConfig.pagination.count);
    const [totalRegistros, setTotalRegistros] = useState(0);
    const [order, setOrder] = useState(ORDENACAO.DIRECAO.ASC);
    const [orderBy, setOrderBy] = useState(ORDENACAO.CAMPOS.DESCRICAO);
    const [buscaEmAndamento, setBuscaEmAndamento] = useState(false);

    const handleClickSort = (campo) => {
        const isAsc = (orderBy === campo) && (order === ORDENACAO.DIRECAO.ASC);
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        setOrder(newOrder);
        setOrderBy(campo);
        setBuscar(true);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setBuscar(true);
    };

    const handleChangeRowsPerPage = event => {
        setCount(event.target.value);
        setPage(global.gConfig.pagination.start);
        setBuscar(true);
    };

    useEffect(() => {
        let abortController;
        if (buscar) {
            abortController = buscarListaParticipantes();
        }

        return () => {
            if (abortController) abortController.abort();
        }
    }, [buscar])

    const buscarListaParticipantes = () => {
        let params = {
            id: idWebconferencia
        };

        if (orderBy && order) {
            params.sort = orderBy;
            params.direction = order;
        }

        const offset = (page * count);
        params.start = offset;
        params.count = count;

        setBuscaEmAndamento(true);
        const abortController = new AbortController();
        axios.get(`${global.gConfig.url_base_tele_educacao}/webconferencia/presenca`, { params, headers: getHeaders(), signal: abortController.signal })
            .then((response) => {
                const { data } = response.data;
                setTotalRegistros(parseInt(data.totalRegistros));
                setParticipantes(data.itens);
            })
            .catch(err => {
                setTotalRegistros(0);
                setParticipantes([]);
            })
            .finally(() => {
                setBuscar(false);
                setBuscaEmAndamento(false);
            });
        return abortController;
    }

    const clickExcluirParticipante = (row) => {
        row.idWebconferencia = idWebconferencia;
        const parametrosAlerta = {
            titulo: strings.atencao,
            mensagem: strings.confirmarExclusao,
            tipo: 'alert',
            open: true,
            opcoes: [
                {
                    title: strings.sim,
                    onClick: () => {
                        setOpen(false);
                        excluirParticipante(row);
                    }

                },
                {
                    title: strings.nao,
                    onClick: () => {
                        setOpen(false);
                    }

                }
            ]
        };
        setParametrosAlerta(parametrosAlerta);
    }

    const excluirParticipante = (row) => {
        mostrarCarregando(true, strings.excluindoParticipante);
        axios.delete(`${global.gConfig.url_base_tele_educacao}/webconferencia/participante`, { data: row, headers: getHeaders() })
            .then((response) => {
                setBuscar(true);
                const parametrosAlerta = {
                    titulo: strings.sucesso,
                    mensagem: strings.sucessoExcluirPresenca,
                    tipo: 'success',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setOpen(false);
                            }

                        }
                    ]
                };
                setParametrosAlerta(parametrosAlerta);
            })
            .catch(err => {
                const { response } = err;
                let mensagem = strings.erro;
                if (response) {
                    const { data } = response;

                    mensagem = data.message;
                    if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagem = arrMensagem.join('\n');
                        }
                    }
                }

                const parametrosAlerta = {
                    titulo: strings.erro,
                    mensagem: mensagem,
                    tipo: 'error',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setOpen(false);
                            }

                        }
                    ]
                };
                setParametrosAlerta(parametrosAlerta);
            })
            .finally(() => {
                mostrarCarregando(false);
            });
    }

    return (
        <>
            <TableContainer className={classes.tableWrapper} component={Paper}>
                <SttTable>
                    <SttTableHead>
                        <SttTableRow>
                            <SttHidden xsDown>
                                <SttTableCell width="22%" sortDirection={orderBy === ORDENACAO.CAMPOS.NOME ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.NOME}
                                        direction={orderBy === ORDENACAO.CAMPOS.NOME ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.NOME)}>
                                        {strings.nome}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="22%" sortDirection={orderBy === ORDENACAO.CAMPOS.MUNICIPIO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.MUNICIPIO}
                                        direction={orderBy === ORDENACAO.CAMPOS.MUNICIPIO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.MUNICIPIO)}>
                                        {strings.cidadeUf}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="22%" sortDirection={orderBy === ORDENACAO.CAMPOS.EQUIPE ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.EQUIPE}
                                        direction={orderBy === ORDENACAO.CAMPOS.EQUIPE ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.EQUIPE)}>
                                        {strings.equipeSaude}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="21%" sortDirection={orderBy === ORDENACAO.CAMPOS.INSTITUICAO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.INSTITUICAO}
                                        direction={orderBy === ORDENACAO.CAMPOS.INSTITUICAO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.INSTITUICAO)}>
                                        {strings.instituicao}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="8%" align="center">
                                    {strings.participante}
                                </SttTableCell>
                                <SttTableCell width="5%" align="center">
                                    {strings.excluir}
                                </SttTableCell>
                            </SttHidden>
                            <SttHidden smUp>
                                <SttTableCell width="100%" colSpan="2">{strings.participantes}</SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>
                    <SttTableBody>
                        {
                            buscaEmAndamento
                                ?
                                <SttTableRow key={-1}>
                                    <SttTableCell colSpan={5}
                                        align="center">
                                        <SttCircularProgress />
                                    </SttTableCell>
                                </SttTableRow>
                                :
                                (
                                    participantes.length === 0 ?
                                        <SttTableRow key={-1}>
                                            <SttTableCell colSpan={5} align="center">
                                                {strings.nenhumRegistroEncontrado}
                                            </SttTableCell>
                                        </SttTableRow>
                                        :
                                        participantes.map((row, index) => (
                                            <SttTableRow hover key={index}>
                                                <SttHidden xsDown>
                                                    <SttTableCell>{row.nome}</SttTableCell>
                                                    <SttTableCell>{row.nome_cidade} / {row.sigla_estado}</SttTableCell>
                                                    <SttTableCell>{row.nome_equipe}</SttTableCell>
                                                    <SttTableCell>{row.nome_instituicao}</SttTableCell>
                                                    <SttTableCell>
                                                        <Chip
                                                            className={classes.chip}
                                                            style={{ backgroundColor: row.convidado ? '#CAE1FF' : '#FFFFFF', border: '1px solid gray' }}
                                                            label={row.convidado ? strings.convidado : strings.funcionario}
                                                        />
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttHidden smUp>
                                                    <SttTableCell width="92%">
                                                        <SttText size="small"><b>{strings.nome}:</b> {row.nome}</SttText>
                                                        <SttText size="small"><b>{strings.cidadeUf}:</b> {row.nome_cidade} / {row.sigla_estado}</SttText>
                                                        <SttText size="small"><b>{strings.equipeSaude}:</b> {row.nome_equipe}</SttText>
                                                        <SttText size="small"><b>{strings.instituicao}:</b> {row.nome_instituicao}</SttText>
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttTableCell align="center">
                                                    {
                                                        user.idFuncionario === row.id_funcionario && permiteConvidado &&
                                                        <SttDeleteButton onClick={() => clickExcluirParticipante(row)} />
                                                    }
                                                </SttTableCell>
                                            </SttTableRow>
                                        ))
                                )
                        }
                    </SttTableBody>
                </SttTable>
            </TableContainer>
            {
                participantes.length > 0 &&
                <SttTablePagination
                    rowsPerPageOptions={[10, 20, 40]}
                    component="div"
                    count={totalRegistros}
                    rowsPerPage={count}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={strings.linhasPorPagina}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${strings.de} ${count}`}
                />
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        mostrarCarregando: (open, titulo) => dispatch(mostrarCarregandoAction(open, titulo)),
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaPresencaPesquisa);