import React, { useState, useContext } from "react";
import {
    SttButton,
    SttModal,
    SttTranslateHook
} from '@stt-componentes/core';
import Form from './form';
import GridAcessos from './grid';

const ModalRegistroAcesso = ({ itemBiblioteca, setVisualizarHistoricoAcesso, idWebconferencia, dataWebconferencia }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const [registrarAcesso, setRegistrarAcesso] = useState(false);
    const [atualizarBusca, setAtualizarBusca] = useState(true);

    return (
        <>
            {
                registrarAcesso && 
                <Form 
                    itemBiblioteca={itemBiblioteca} 
                    setRegistrarAcesso={setRegistrarAcesso} 
                    setAtualizarBusca={setAtualizarBusca} 
                    dataWebconferencia={dataWebconferencia} 
                />
            }
            <SttModal
                title={strings.registrosAcesso}
                open={true}
                maxWidth="md"
                outModalClose={() => setVisualizarHistoricoAcesso(false)}
                iconClose={() => setVisualizarHistoricoAcesso(false)}
                fullWidth={true}
                children={
                    <>
                        <GridAcessos idWebconferencia={idWebconferencia} atualizarBusca={atualizarBusca} setAtualizarBusca={setAtualizarBusca} />
                    </>
                }
                footer={
                    <div>
                        <SttButton variant="contained"
                            color="primary"
                            onClick={() => setRegistrarAcesso(true)}
                        >
                            {strings.adicionar}
                        </SttButton>
                        <SttButton variant="contained"
                            color="secondary"
                            onClick={() => setVisualizarHistoricoAcesso(false)}
                        >
                            {strings.fechar}
                        </SttButton>
                    </div>
                }
            />
        </>
    );
}

export default ModalRegistroAcesso;