import * as yup from 'yup';
import {
    AREA_TEMATICA,
    CATEGORIAS,
    DADOS_ATIVIDADE,
    TIPO_ATIVIDADE,
    CATEGORIAS_PUBLICOS_ALVO
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [DADOS_ATIVIDADE]: yup.object().shape({
            [TIPO_ATIVIDADE]: yup
                .object().shape({
                    id: yup.number(),
                    descricao: yup.string(),
                })
                .nullable()
                .required(strings.campoObrigatorio),
            [AREA_TEMATICA]: yup
                .object().shape({
                    id: yup.number(),
                    descricao: yup.string(),
                })
                .nullable()
                .required(strings.campoObrigatorio),
            [CATEGORIAS]: yup.array().of(
                yup.object().shape({
                    id: yup.number(),
                    descricao: yup.string()
                }).required()
            ).min(1, strings.categoriaObrigatoria)
                .required(),
                [CATEGORIAS_PUBLICOS_ALVO]: yup.array().of(
                yup.object().shape({
                    id: yup.number(),
                    descricao: yup.string()
                }).required()
            ).min(1, strings.erroSelecaoPublicoAlvo)
                .required(),
        }).required()
    });
}
