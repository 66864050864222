import stringsComuns from '@stt-utilitarios/core/src/strings/comum';

export const CONSTANTES_PERFIS = {
    ADMINISTRADOR: 'administrador',
    ADMINISTRADOR_TELESSAUDE: 'adminTelessaude',
    ADMINISTRADOR_ESTADUAL: 'adminEstadual',
    ADMINISTRADOR_TELEEDUCACAO: 'adminTeleeducacao',
    VISUALIZADOR_WEB: 'visualizadorWeb'
}

export const PERMISSOES = {
    TELEEDUCACAO: 'TELEEDUCACAO_TELEEDUCACAO',
    MOODLE: 'TELEEDUCACAO_MOODLE',
    PESQUISAR_WEB: 'TELEEDUCACAO_PESQUISAR_WEB',
    CADASTRAR_WEB: 'TELEEDUCACAO_CADASTRAR_WEB',
    AVALIAR_WEB: 'TELEEDUCACAO_AVALIAR_WEB',
    ADMINISTRATIVO: 'TELEEDUCACAO_ADMINISTRATIVO',
    INATIVAR_WEB: 'TELEEDUCACAO_INATIVAR_WEB',
    PESQUISAR_CURSO: 'TELEEDUCACAO_PESQUISAR_CURSO',
    CADASTRAR_CURSO: 'TELEEDUCACAO_CADASTRAR_CURSO'
}


export const SITUACAO_TODOS = 'T';
export const SITUACAO_ATIVO = 'A';
export const SITUACAO_INATIVO = 'I';

export const SITUACOES = [

    { sigla: SITUACAO_TODOS, descricao: stringsComuns.todos },
    { sigla: SITUACAO_ATIVO, descricao: stringsComuns.ativo },
    { sigla: SITUACAO_INATIVO, descricao: stringsComuns.inativo },
]

export const TIPO_PESQUISA_WEBS = {
    AO_VIVO: 'AO_VIVO',
    PROXIMAS_WEBS: 'PROXIMAS_WEBS',
    WEBS_GRAVADAS: 'WEBS_GRAVADAS'
}

const SITUACAO_INSCRICAO_ABERTA = 'IA';
const SITUACAO_PROXIMOS_CURSOS = 'PC';
const SITUACAO_CURSOS_ANDAMENTO = 'CA';
const SITUACAO_CURSOS_ENCERRADOS = 'CE';

export const SITUACAO_PESQUISA = [
    { identificador: SITUACAO_INSCRICAO_ABERTA, descricao: 'Inscrições abertas'},
    { identificador: SITUACAO_PROXIMOS_CURSOS, descricao: 'Próximos cursos'},
    { identificador: SITUACAO_CURSOS_ANDAMENTO, descricao: 'Cursos em andamento'},
    { identificador: SITUACAO_CURSOS_ENCERRADOS, descricao: 'Cursos encerrados'},
]

export const SITUACAO_CURSO = [
    { id: '1', descricao: 'Curso não iniciado' },
    { id: '2', descricao: 'Curso em andamento' },
    { id: '3', descricao: 'Curso encerrado' },
]

export const SITUACAO_INSCRICAO = [
    { id: '1', descricao: 'Inscrição aberta' },
    { id: '2', descricao: 'Inscrição fechada' },
]

export const CONSTANTES_SITUACAO_CURSO = {
    NAO_INICIADO: 'NI',
    EM_ANDAMENTO: 'A',
    ENCERRADO: 'E'
}

export const CONSTANTES_SITUACAO_INSCRICAO = {
    ABERTA: 'A',
    FECHADA: 'F'
}

export const CONSTANTES_CORES_SITUACAO_CURSO_INSCRICAO = {
    CURSO_NAO_INICIADO_INSCRICOES_FECHADAS: '#d0cece',
    CURSO_NAO_INICIADO_INSCRICOES_ABERTAS: '#deebf7',
    CURSO_EM_ANDAMENTO_INSCRICOES_FECHADAS: '#fff2cc',
    CURSO_EM_ANDAMENTO_INSCRICOES_ABERTAS: '#e2f0d9',
    CURSO_ENCERRADO: '#fbe5d6'
}

export const VINCULO_PERFIL_ESTADUAL = 'E';

export const CURSO_FORMATO_INSTRUCAO = [
    { identificador: 'A', descricao: 'Autoinstrucional' },
    { identificador: 'T', descricao: 'Com tutoria' },
]

export const MYME_TYPE_ARQUIVOS = {
    JPEG: 'image/jpeg',
    PNG: 'image/png',
    PDF: 'application/pdf',
}

export const MYME_TYPE_PERMITIDOS = Object.values(MYME_TYPE_ARQUIVOS);

export const CONSTANTE_TIPO_ARQUIVO_IMAGEM_CURSO = 'IC';

export const CONSTANTE_TIPO_ARQUIVO_RESUMO_CURSO = 'RC';

export const CONSTANTE_TIPO_ARQUIVO_ESTRUTURA_CONTEUDO_PROGRAMATICO = 'EC';

export const CONSTANTE_MOSTRAR_CURSO_DATA_DE = [
    { identificador: 'AI', descricao: 'Antes do início das inscrições' },
    { identificador: 'II', descricao: 'No início das inscrições' },
    { identificador: 'IC', descricao: 'No início do curso' },
];

export const CONSTANTE_MOSTRAR_CURSO_DATA_DE_ANTES_INICIO = 'AI';

export const CONSTANTE_MOSTRAR_CURSO_DATA_ATE = [
    { identificador: 'FC', descricao: 'Final do curso' },
    { identificador: 'PC', descricao: 'Após o final do curso' },
    { identificador: 'IN', descricao: 'Indefinido' }
];

export const CONSTANTE_MOSTRAR_CURSO_DATA_ATE_APOS_FINAL_CURSO = 'PC';

export const CONSTANTE_PAPEL_ATRIBUIDO_PADRAO = 'Estudante';

export const MYME_TYPE_ARQUIVOS_IMAGEM_CURSO = {
    JPG: 'image/jpg',
    JPEG: 'image/jpeg',
    PNG: 'image/png'
}

export const MYME_TYPE_PERMITIDOS_IMAGEM_CURSO = Object.values(MYME_TYPE_ARQUIVOS_IMAGEM_CURSO);

export const COR_FUNDO_BANNER = {
    INSCREVA_SE_A_PARTIR_DE: '#ffc000',
    INSCRICOES_ABERTAS: '#70AD47',
    VAGAS_ESGOTADAS: '#385723',
    CURSO_EM_ANDAMENTO: '#7030A0',
    CURSO_ENCERRADO: '#FF0000',
    CURSO_NAO_INICIADO_INSCRICOES_FECHADAS: '#d0cece',
}