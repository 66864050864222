import * as yup from 'yup';
import {
    AVALIACAO, BOM, MUITO_BOM, REGULAR, RUIM, TITULO
} from './fieldnames';


export default (strings) => {
    return yup.object().shape({
        [AVALIACAO]: yup.array().of(
            yup.object().shape({
                [TITULO]: yup.string(),
                [MUITO_BOM]: yup
                    .number()
                    .required(strings.campoObrigatorio),
                [BOM]: yup
                    .number()
                    .required(strings.campoObrigatorio),
                [REGULAR]: yup
                    .number()
                    .required(strings.campoObrigatorio),
                [RUIM]: yup
                    .number()
                    .required(strings.campoObrigatorio)
            })
        )
    })
};
