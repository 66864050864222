import React, { useEffect, useRef, useState, useContext } from 'react';
import {
    SttButton,
    SttTranslateHook,
    SttModal
} from '@stt-componentes/core';
import { connect } from 'react-redux';
import axios from 'axios';
import { getHeaders } from '../../../request';
import FormEquipeSaude from './equipeSaude/form';
import FormParticipante from './participante/form';
import {
    setParametrosAlerta as setParametrosAlertaAction,
    setOpen as setOpenAction
} from "../../../reducers/actions/alerta";
import Pesquisa from './pesquisa';
import { mostrarCarregando as mostrarCarregandoAction } from '../../../reducers/actions/carregando';

const ListaPresenca = ({ user, idWebconferencia, urlWebconferencia, callback, setParametrosAlerta, setOpen, mostrarCarregando }) => {
    const janelaRef = useRef();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const [modalOpen, setModalOpen] = useState(false);
    const [registrarEquipe, setRegistrarEquipe] = useState(false);
    const [registrarParticipante, setRegistrarParticipante] = useState(false);
    const [buscar, setBuscar] = useState(true);
    const [permiteConvidado, setPermiteConvidado] = useState(false);

    useEffect(() => {
        if (idWebconferencia) {
            acessarWebconferencia(idWebconferencia);
        }
    }, [idWebconferencia]);

    const acessarWebconferencia = (idWeb) => {
        mostrarCarregando(true);
        axios.get(`${global.gConfig.url_base_tele_educacao}/acesso-webconferencia/${idWeb}`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { message, data } = response.data;
                    if (data.semEquipeNemInstituicao) {
                        const parametrosAlerta = {
                            titulo: strings.atencao,
                            mensagem: strings.semEquipeNemInstituicao,
                            tipo: 'alert',
                            open: true,
                            opcoes: [
                                {
                                    title: strings.ok,
                                    onClick: () => {
                                        setOpen(false);
                                        setModalOpen(true);
                                    }

                                }
                            ]
                        };
                        setParametrosAlerta(parametrosAlerta);
                    } else if (data.acessoLiberado) {
                        setPermiteConvidado(data.aceitaConvidado);
                        if (!data.jaRegistrado) {
                            // Registra o primeiro acesso a webconferência
                            mostrarCarregando(true);
                            axios.post(`${global.gConfig.url_base_tele_educacao}/acesso-webconferencia`, { idWebconferencia: idWeb }, { headers: getHeaders() })
                                .then((response) => {
                                    setModalOpen(true);
                                    setRegistrarEquipe(true);
                                })
                                .catch(err => console.log(err))
                                .finally(() => {
                                    mostrarCarregando(false);
                                });
                            return;
                        }
                        if (!data.equipeRegistrada) {
                            // Já havia sido feito o registro inicial, mas não tinha sido registrado uma ESF
                            setRegistrarEquipe(true);
                        }
                        // Acesso já registrado e equipe já selecionada
                        setModalOpen(true);
                    } else {
                        const parametrosAlerta = {
                            titulo: strings.atencao,
                            mensagem: message,
                            tipo: 'alert',
                            open: true,
                            opcoes: [
                                {
                                    title: strings.ok,
                                    onClick: () => {
                                        fecharListaPresenca();
                                        abrirWebconferencia();
                                        setOpen(false);
                                    }

                                }
                            ]
                        };
                        setParametrosAlerta(parametrosAlerta);
                    }
                }
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                mostrarCarregando(false);
            });
    }

    const fecharListaPresenca = () => {
        callback();
        setModalOpen(false);
    }

    const abrirWebconferencia = () => {
        let urlWebconferenciaFormatada = urlWebconferencia;
        if (urlWebconferenciaFormatada && urlWebconferenciaFormatada.includes(global.gConfig.url_padrao_conferencia_web)) {
            if (user?.email && user?.nome) {
                var parametros = [
                    `user[email]=${user.email}`,
                    `user[name]=${user.nome}`,
                ];
                urlWebconferenciaFormatada = `${urlWebconferenciaFormatada}/join?` + parametros.join('&');
                console.log('Endereço da sala webconferencia formatada', urlWebconferenciaFormatada);
            }
        }

        if (!janelaRef.current || janelaRef.current.closed) {
            janelaRef.current = window.open(urlWebconferenciaFormatada, 'Webconferência STT');
        }
        janelaRef.current.focus();
    }

    const [participantes, setParticipantes] = useState([]);

    return (
        <SttModal
            title={strings.listaPresenca}
            open={modalOpen}
            maxWidth="lg"
            fullWidth={true}
            outModalClose={fecharListaPresenca}
            iconClose={fecharListaPresenca}
            children={
                <>
                    {
                        registrarEquipe &&
                        <FormEquipeSaude idWebconferencia={idWebconferencia}
                            callbackCancelar={fecharListaPresenca}
                            callbackRegistroEquipe={() => setBuscar(true)} />
                    }
                    {
                        registrarParticipante &&
                        <FormParticipante idWebconferencia={idWebconferencia}
                            callbackRegistroParticipante={() => {
                                setBuscar(true);
                                setRegistrarParticipante(false);
                            }}
                            participantes={participantes}
                            callbackCancelar={() => setRegistrarParticipante(false)} />
                    }
                    <Pesquisa idWebconferencia={idWebconferencia}
                        setBuscar={setBuscar}
                        participantes={participantes}
                        setParticipantes={setParticipantes}
                        permiteConvidado={permiteConvidado}
                        buscar={buscar} />
                </>
            }
            footer={
                <>
                    {
                        permiteConvidado &&
                        <SttButton variant="outlined"
                            color="primary"
                            onClick={() => setRegistrarParticipante(true)}>
                            {strings.adicionarParticipante}
                        </SttButton>
                    }
                    <SttButton variant="contained"
                        color="secondary"
                        onClick={() => {
                            abrirWebconferencia();
                        }}>
                        {strings.seguirWebconferencia}
                    </SttButton>
                </>
            }
        />
    )
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        mostrarCarregando: (open, titulo) => dispatch(mostrarCarregandoAction(open, titulo)),
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaPresenca);