import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableSortLabel,
    SttTablePagination,
    SttCircularProgress,
    SttHidden,
    SttText,
    SttAddButton,
    SttTranslateHook
} from '@stt-componentes/core';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import axios from 'axios';
import { getHeaders } from '../../../request';
import { connect } from 'react-redux';
import FormPergunta from './pergunta/form';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(2)
    },
    table: {
        overflow: 'auto'
    },
    notification: {
        display: 'flex',
        flexDirection: 'column'
    },
    alert: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    chip: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        height: 'auto',
        '& span': {
            whiteSpace: 'pre-wrap'
        }
    }
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        EQUIPE: 'nome_equipe',
        INSTITUICAO: 'nome_instituicao'
    }
};

const ListaEquipesPesquisa = ({ idWebconferencia }) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const [participantes, setParticipantes] = useState([]);
    const [page, setPage] = useState(global.gConfig.pagination.start);
    const [count, setCount] = useState(global.gConfig.pagination.count);
    const [totalRegistros, setTotalRegistros] = useState(0);
    const [order, setOrder] = useState(ORDENACAO.DIRECAO.ASC);
    const [orderBy, setOrderBy] = useState(ORDENACAO.CAMPOS.DESCRICAO);
    const [buscaEmAndamento, setBuscaEmAndamento] = useState(false);
    const [buscar, setBuscar] = useState(true);
    const [registrarPerguntaEquipe, setRegistrarPerguntaEquipe] = useState();

    const handleClickSort = (campo) => {
        const isAsc = (orderBy === campo) && (order === ORDENACAO.DIRECAO.ASC);
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        setOrder(newOrder);
        setOrderBy(campo);
        setBuscar(true);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setBuscar(true);
    };

    const handleChangeRowsPerPage = event => {
        setCount(event.target.value);
        setPage(global.gConfig.pagination.start);
        setBuscar(true);
    };

    useEffect(() => {
        let abortController;
        if (buscar) {
            abortController = buscarListaParticipantes();
        }

        return () => {
            if (abortController) abortController.abort();
        }
    }, [buscar])

    const buscarListaParticipantes = () => {
        let params = {
            idWebconferencia
        };

        if (orderBy && order) {
            params.sort = orderBy;
            params.direction = order;
        }

        const offset = (page * count);
        params.start = offset;
        params.count = count;

        setBuscaEmAndamento(true);
        const abortController = new AbortController();
        axios.get(`${global.gConfig.url_base_tele_educacao}/webconferencia/equipe-participante`, { params, headers: getHeaders(), signal: abortController.signal })
            .then((response) => {
                const { data } = response.data;
                setTotalRegistros(parseInt(data.totalRegistros));
                setParticipantes(data.itens);
            })
            .catch(err => {
                setTotalRegistros(0);
                setParticipantes([]);
            })
            .finally(() => {
                setBuscar(false);
                setBuscaEmAndamento(false);
            });
        return abortController;
    }

    const clickAdicionarPergunta = (row) => {
        setRegistrarPerguntaEquipe(row);
    }

    return (
        <>
            <TableContainer className={classes.tableWrapper} component={Paper}>
                <SttTable>
                    <SttTableHead>
                        <SttTableRow>
                            <SttHidden xsDown>
                                <SttTableCell width="45%" sortDirection={orderBy === ORDENACAO.CAMPOS.EQUIPE ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.EQUIPE}
                                        direction={orderBy === ORDENACAO.CAMPOS.EQUIPE ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.EQUIPE)}>
                                        {strings.equipeSaude}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="45%" sortDirection={orderBy === ORDENACAO.CAMPOS.INSTITUICAO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.INSTITUICAO}
                                        direction={orderBy === ORDENACAO.CAMPOS.INSTITUICAO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.INSTITUICAO)}>
                                        {strings.instituicao}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="10%" align="center">
                                    {/* {strings.excluir} */}Pergunta
                                </SttTableCell>
                            </SttHidden>
                            <SttHidden smUp>
                                <SttTableCell width="100%" colSpan="2">{strings.participantes}</SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>
                    <SttTableBody>
                        {
                            buscaEmAndamento
                                ?
                                <SttTableRow key={-1}>
                                    <SttTableCell colSpan={3}
                                        align="center">
                                        <SttCircularProgress />
                                    </SttTableCell>
                                </SttTableRow>
                                :
                                (
                                    participantes.length === 0 ?
                                        <SttTableRow key={-1}>
                                            <SttTableCell colSpan={3} align="center">
                                                {strings.nenhumRegistroEncontrado}
                                            </SttTableCell>
                                        </SttTableRow>
                                        :
                                        participantes.map((row, index) => (
                                            <SttTableRow hover key={index}>
                                                <SttHidden xsDown>
                                                    <SttTableCell>{row.nome_equipe}</SttTableCell>
                                                    <SttTableCell>{row.nome_instituicao}</SttTableCell>
                                                </SttHidden>
                                                <SttHidden smUp>
                                                    <SttTableCell width="92%">
                                                        <SttText size="small"><b>{strings.equipeSaude}:</b> {row.nome_equipe}</SttText>
                                                        <SttText size="small"><b>{strings.instituicao}:</b> {row.nome_instituicao}</SttText>
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttTableCell align="center">
                                                    <SttAddButton onClick={() => clickAdicionarPergunta(row)} />
                                                </SttTableCell>
                                            </SttTableRow>
                                        ))
                                )
                        }
                    </SttTableBody>
                </SttTable>
            </TableContainer>
            {
                participantes.length > 0 &&
                <SttTablePagination
                    rowsPerPageOptions={[10, 20, 40]}
                    component="div"
                    count={totalRegistros}
                    rowsPerPage={count}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={strings.linhasPorPagina}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${strings.de} ${count}`}
                />
            }
            {
                registrarPerguntaEquipe &&
                <FormPergunta idWebconferencia={idWebconferencia}
                    equipeSaude={registrarPerguntaEquipe}
                    callbackCancelar={() => setRegistrarPerguntaEquipe(null)} />
            }
        </>
    )
}

export default ListaEquipesPesquisa;