import React, { useRef, useState, useContext } from 'react';
import {
    SttButton,
    SttTranslateHook,
    SttGrid,
    SttInput,
    SttModal,
} from '@stt-componentes/core';
import { connect } from 'react-redux';
import axios from 'axios';
import { getHeaders } from '../../../../request';
import {
    Formik,
    FastField
} from 'formik';
import {
    setParametrosAlerta as setParametrosAlertaAction,
    setOpen as setOpenAction
} from "../../../../reducers/actions/alerta";
import HttpStatus from 'http-status-codes';
import validationSchema from './validationSchema';
import { mostrarCarregando as mostrarCarregandoAction } from '../../../../reducers/actions/carregando';

const ListaEquipeResumoForm = ({ idWebconferencia, resumoWebconferencia, setParametrosAlerta, setOpen, mostrarCarregando, atualizarResumo, cancelarEdicao }) => {
    const schema = validationSchema(strings);
    const formRef = useRef();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const [modalOpen, setModalOpen] = useState(true);

    const enviarResumo = (dados, setSubmitting) => {
        setSubmitting(true);
        mostrarCarregando(true, strings.salvandoMensagemEspera);
        axios.post(`${global.gConfig.url_base_tele_educacao}/webconferencia/resumo`, dados, { headers: getHeaders() })
            .then((response) => {
                const parametrosAlerta = {
                    titulo: strings.sucesso,
                    mensagem: strings.sucessoRegistroResumo,
                    tipo: 'success',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setOpen(false);
                                setModalOpen(false);
                                atualizarResumo(dados.resumo.trim());
                            }

                        }
                    ],
                    onClose: () => {
                        setOpen(false);
                        setModalOpen(false);
                        atualizarResumo(dados.resumo.trim());
                    }
                };
                setParametrosAlerta(parametrosAlerta);
            })
            .catch(err => {
                const { response } = err;
                let mensagem = strings.erro;
                if (response) {
                    const { data } = response;

                    mensagem = data.message;
                    if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagem = arrMensagem.join('\n');
                        }
                    }
                }

                const parametrosAlerta = {
                    titulo: strings.erro,
                    mensagem: mensagem,
                    tipo: 'error',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setOpen(false);
                            }

                        }
                    ]
                };
                setParametrosAlerta(parametrosAlerta);
            })
            .finally(() => {
                mostrarCarregando(false);
                if (setSubmitting) {
                    setSubmitting(false);
                }
            });
    }

    return (
        <SttModal
            title={strings.resumoWebconferencia}
            open={modalOpen}
            maxWidth="md"
            fullWidth={true}
            outModalClose={cancelarEdicao}
            iconClose={cancelarEdicao}
            children={
                <Formik
                    innerRef={formRef}
                    initialValues={{
                        resumo: resumoWebconferencia || '',
                        id: idWebconferencia
                    }}
                    validationSchema={schema}
                    onSubmit={(dados, { setSubmitting }) => {
                        setSubmitting(true);
                        enviarResumo(dados, setSubmitting);
                    }}
                >
                    {
                        ({
                            handleSubmit,
                        }) => (
                            <>
                                <form onSubmit={handleSubmit} noValidate>
                                    <SttGrid container spacing={1}>
                                        <SttGrid item xs={12}>
                                            <FastField name="resumo">
                                                {({
                                                    meta,
                                                    field,
                                                }) => (
                                                    <SttInput
                                                        {...field}
                                                        multiline
                                                        required={true}
                                                        minRows={5}
                                                        maxRows={9}
                                                        label={strings.resumo}
                                                        error={meta.touched && meta.error ? true : false}
                                                        helperText={meta.touched && meta.error ? meta.error : undefined}
                                                    />
                                                )}
                                            </FastField>
                                        </SttGrid>
                                    </SttGrid>
                                </form>
                            </>
                        )
                    }
                </Formik>
            }
            footer={
                <>
                    <SttButton variant="contained"
                        disabled={formRef.current && formRef.current.isSubmitting}
                        color="primary"
                        onClick={() => {
                            formRef.current.submitForm();
                        }}>
                        {strings.confirmar}
                    </SttButton>
                    <SttButton variant="outlined"
                        color="primary"
                        onClick={cancelarEdicao}>
                        {strings.fechar}
                    </SttButton>
                </>

            }
        />
    )
};

const mapDispatchToProps = (dispatch) => {
    return {
        mostrarCarregando: (open, titulo) => dispatch(mostrarCarregandoAction(open, titulo)),
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open)),
    };
};

export default connect(null, mapDispatchToProps)(ListaEquipeResumoForm);