import * as yup from 'yup';
import {
    CARGA_HORARIA,
    DADOS_GERAIS,
    DATA_HORA,
    DESCRICAO,
    ENDERECO_SALA,
    INSTITUICAO_RESPONSAVEL,
    PUBLICA,
    PUBLICOS_ALVO,
    TITULO,
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [DADOS_GERAIS]: yup.object().shape({
            [INSTITUICAO_RESPONSAVEL]: yup
                .object().shape({
                    id: yup.number(),
                    nome: yup.string(),
                })
                .nullable()
                .required(strings.campoObrigatorio),
            [ENDERECO_SALA]: yup
                .string()
                .trim()
                .url(strings.urlInvalida)
                .required(strings.campoObrigatorio),
            [DATA_HORA]: yup.date()
                .typeError(strings.dataInvalida)
                .required(strings.campoObrigatorio),
            [TITULO]: yup
                .string(strings.urlInvalida)
                .trim()
                .min(10, strings.tituloInvalidoMin)
                .max(200, strings.erroTituloMax200Caracteres)
                .required(strings.campoObrigatorio),
            [DESCRICAO]: yup
                .string()
                .trim()
                .min(10, strings.descricaoInvalidaMin)
                .required(strings.campoObrigatorio),
            [CARGA_HORARIA]: yup
                .number()
                .min(1, strings.cargaHorariaMinima)
                .required(strings.campoObrigatorio),
            [PUBLICOS_ALVO]: yup.array().when(PUBLICA, {
                is: (val) => !val,
                then: yup.array().of(
                    yup.object().shape({
                        id: yup.number(),
                        descritor: yup.string()
                    }).required()
                ).min(1, strings.cboObrigatorioWebconferencia)
                    .required()
            }).nullable(),
        }).required()
    });
}
