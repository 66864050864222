export const tratarUrl = (url) => {
    try {
        // Adiciona o esquema "https://" se a URL não tiver um esquema especificado
        if (!url.match(/^[a-z]+:\/\//i)) {
            url = 'https://' + url;
        }

        const urlObj = new URL(url);

        if (urlObj.protocol === '') {
            urlObj.protocol = 'https:';
        }

        return urlObj.toString();
    } catch (e) {
        console.error('Erro ao tratar a URL:', e);
        return null;
    }
}

export const recuperarObjetoPorId = (id, arrayObjetos, chave = 'id') => {
    return arrayObjetos.find(objeto => objeto[chave] === id);
}
