import { combineReducers } from 'redux';
import TYPES from './types';
import alertaReducer from './alertaReducer';
import carregandoReducer from './carregandoReducer';
import webconferenciaReducer from './webconferenciaReducer';
import cursoReducer from './cursoReducer';

const INITIAL_STATE = {
    isAuthenticated: false,
    message: '',
    user: {}
};

const index = (state, action) => {
    if(!state) {
        state = INITIAL_STATE;
    }
    
    const { type, payload } = action;

    switch (type) {
        case TYPES.SET_AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: payload.authenticated
            }
        case TYPES.SET_USER:
            return {
                ...state,
                user: payload.user
            }
        case TYPES.LOGOUT:
            return INITIAL_STATE;

        case TYPES.SET_MESSAGE:
            return {
                ...state,
                message: payload.message
            }

            default:
            return state;
    }
}

export const Reducers = combineReducers({
    index,
    alertaReducer,
    carregandoReducer,
    webconferenciaReducer,
    cursoReducer
});
