import React, { useRef, useState, useContext } from 'react';
import {
    SttButton,
    SttTranslateHook,
    SttModal
} from '@stt-componentes/core';
import { connect } from 'react-redux';
import FormResumo from './resumo/form';
import {
    setParametrosAlerta as setParametrosAlertaAction,
    setOpen as setOpenAction
} from "../../../reducers/actions/alerta";
import Pesquisa from './pesquisa';
import { temPermissaoVinculoEstadual } from '../../../security/acl';

const ListaEquipeParticipante = ({ user, idWebconferencia, urlWebconferencia, resumoWebconferencia, callback, atualizarResumo, estadoWebconferencia }) => {
    const janelaRef = useRef();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const [modalOpen, setModalOpen] = useState(true);
    const [editarResumo, setEditarResumo] = useState(false);

    const fecharListaEquipes = () => {
        callback();
        setModalOpen(false);
    }

    const abrirWebconferencia = () => {
        let urlWebconferenciaFormatada = urlWebconferencia;
        if (urlWebconferenciaFormatada && urlWebconferenciaFormatada.includes(global.gConfig.url_padrao_conferencia_web)) {
            if (user?.email && user?.nome) {
                var parametros = [
                    `user[email]=${user.email}`,
                    `user[name]=${user.nome}`,
                ];
                urlWebconferenciaFormatada = `${urlWebconferenciaFormatada}/join?` + parametros.join('&');
                console.log('Endereço da sala lista equipe participante', urlWebconferenciaFormatada);
            }
        }

        if (!janelaRef.current || janelaRef.current.closed) {
            janelaRef.current = window.open(urlWebconferenciaFormatada, 'Webconferência STT');
        }
        janelaRef.current.focus();
    }

    return (
        <SttModal
            title={strings.listaEquipesParticipante}
            open={modalOpen}
            maxWidth="lg"
            fullWidth={true}
            outModalClose={fecharListaEquipes}
            iconClose={fecharListaEquipes}
            children={
                <>
                    {
                        editarResumo &&
                        <FormResumo idWebconferencia={idWebconferencia}
                            atualizarResumo={(resumoNovo) => {
                                atualizarResumo(resumoNovo);
                                setEditarResumo(false);
                            }}
                            cancelarEdicao={() => setEditarResumo(false)}
                            resumoWebconferencia={resumoWebconferencia} />
                    }
                    <Pesquisa idWebconferencia={idWebconferencia} />
                </>
            }
            footer={
                <>
                    {
                        temPermissaoVinculoEstadual(user, null, estadoWebconferencia) &&
                        <SttButton variant="outlined"
                            color="primary"
                            onClick={() => setEditarResumo(true)}>
                            {strings.alterarResumo}
                        </SttButton>
                    }

                    <SttButton variant="contained"
                        color="secondary"
                        onClick={() => {
                            abrirWebconferencia();
                        }}>
                        {strings.seguirWebconferencia}
                    </SttButton>
                </>
            }
        />
    )
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaEquipeParticipante);
