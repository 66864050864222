import { CONSTANTE_MOSTRAR_CURSO_DATA_ATE } from "../../common/Constants";
import { CONSTANTE_MOSTRAR_CURSO_DATA_DE } from "../../common/Constants";
import { CONSTANTE_TIPO_ARQUIVO_IMAGEM_CURSO, CURSO_FORMATO_INSTRUCAO } from "../../common/Constants";
import { ANEXO, ANEXOS, ANEXOS_ALTERADOS, ANEXOS_EXCLUIDOS, TIPO_ANEXO } from "../../componentes/interno/cadastro/curso/anexos/form/fieldNames";
import { DECS } from "../../componentes/interno/cadastro/curso/dadosDecs/form/fieldNames";
import { CARGA_HORARIA, DADOS_GERAIS, DESCRICAO, ENDERECO_CURSO, IMAGEM, PUBLICO, INSTITUICAO_RESPONSAVEL, TITULO, PUBLICOS_ALVO, FORMATO_INSTRUCAO, FORMATO_ATIVIDADE, CATEGORIA, TITULO_BREVE, CONTATO_RESPONSAVEL, TURMA, VAGAS_ESGOTADAS } from "../../componentes/interno/cadastro/curso/dadosGerais/form/fieldNames";
import { DATA_INICIAL_INSCRICAO, DETALHES_CURSO, DATA_FINAL_CURSO, DATA_FINAL_INSCRICAO, DATA_INICIAL_CURSO, PRORROGACAO, CATEGORIAS_PUBLICOS_ALVO, CATEGORIAS_AREA_TEMATICA, AREA_TEMATICA, DATA_PRORROGADA, MOSTRAR_CURSO_DATA_DE, DATA_MOSTRAR_CURSO, MOSTRAR_CURSO_DATA_ATE, DATA_OCULTAR_CURSO, NUMERO_VAGAS, SUMARIO } from "../../componentes/interno/cadastro/curso/detalhesCurso/form/fieldNames";
import { recuperarObjetoPorId } from '../../util';
import moment from 'moment';

const valoresPadrao = {
    [DADOS_GERAIS]: {
        [INSTITUICAO_RESPONSAVEL]: null,
        [ENDERECO_CURSO]: '',
        [TITULO]: '',
        [TURMA]: '',
        [DESCRICAO]: '',
        [CARGA_HORARIA]: '',
        [IMAGEM]: null,
        [FORMATO_INSTRUCAO]: null,
        [FORMATO_ATIVIDADE]: null,
        [PUBLICO]: true,
        [PUBLICOS_ALVO]: [],
        [CATEGORIA]: null,
        [TITULO_BREVE]: '',
        [CONTATO_RESPONSAVEL]: '',
        [VAGAS_ESGOTADAS]: false
    },
    [DETALHES_CURSO]: {
        [DATA_INICIAL_INSCRICAO]: null,
        [DATA_FINAL_INSCRICAO]: null,
        [DATA_INICIAL_CURSO]: null,
        [DATA_FINAL_CURSO]: null,
        [PRORROGACAO]: 15,
        [NUMERO_VAGAS]: '',
        [CATEGORIAS_PUBLICOS_ALVO]: [],
        [AREA_TEMATICA]: null,
        [CATEGORIAS_AREA_TEMATICA]: [],
        [DATA_PRORROGADA]: null,
        [MOSTRAR_CURSO_DATA_DE]: null,
        [DATA_MOSTRAR_CURSO]: null,
        [MOSTRAR_CURSO_DATA_ATE]: null,
        [DATA_OCULTAR_CURSO]: null
    },
    [ANEXOS]: [],
    [ANEXOS_EXCLUIDOS]: [],
    [ANEXOS_ALTERADOS]: []
};

const preencherDadosGerais = (formulario, dadosAlteracao) => {
    if (dadosAlteracao) {
        formulario.id = dadosAlteracao.id;

        const dadosGerais = {
            [TITULO]: dadosAlteracao.titulo,
            [TURMA]: dadosAlteracao.turma || '',
            [INSTITUICAO_RESPONSAVEL]: dadosAlteracao.instituicao_responsavel,
            [ENDERECO_CURSO]: dadosAlteracao.url,
            [DESCRICAO]: dadosAlteracao.descricao,
            [CARGA_HORARIA]: dadosAlteracao.carga_horaria || '',
            [FORMATO_INSTRUCAO]: recuperarObjetoPorId(dadosAlteracao.identificador_formato_instrucao, CURSO_FORMATO_INSTRUCAO, 'identificador'),
            [FORMATO_ATIVIDADE]: dadosAlteracao.id_formato_atividade,
            [PUBLICO]: dadosAlteracao.publico,
            [IMAGEM]: null,
            [PUBLICOS_ALVO]: [],
            [CATEGORIA]: dadosAlteracao.id_categoria_curso,
            [TITULO_BREVE]: dadosAlteracao.titulo_breve,
            [CONTATO_RESPONSAVEL]: dadosAlteracao.contato_responsavel,
            [PUBLICOS_ALVO]: dadosAlteracao.publicosAlvo,
            [VAGAS_ESGOTADAS]: dadosAlteracao.vagas_esgotadas
        }

        formulario[DADOS_GERAIS] = dadosGerais;
    }
}

const preencherDetalhesCurso = (formulario, dadosAlteracao) => {
    if (dadosAlteracao) {
        formulario.id = dadosAlteracao.id;

        const detalhesCurso = {
            [DATA_INICIAL_INSCRICAO]: moment.utc(dadosAlteracao.data_inicio_inscricao),
            [DATA_FINAL_INSCRICAO]: moment.utc(dadosAlteracao.data_fim_inscricao),
            [DATA_INICIAL_CURSO]: moment.utc(dadosAlteracao.data_inicio),
            [DATA_FINAL_CURSO]: moment.utc(dadosAlteracao.data_fim),
            [PRORROGACAO]: dadosAlteracao.dias_prorrogacao || '',
            [CATEGORIAS_PUBLICOS_ALVO]: dadosAlteracao.categoriasPublicosAlvo,
            [CATEGORIAS_AREA_TEMATICA]: dadosAlteracao.categorias,
            [NUMERO_VAGAS]: dadosAlteracao.numero_vagas_ofertadas,
            [MOSTRAR_CURSO_DATA_DE]: recuperarObjetoPorId(dadosAlteracao.identificador_momento_mostrar_curso_de, CONSTANTE_MOSTRAR_CURSO_DATA_DE, 'identificador'),
            [MOSTRAR_CURSO_DATA_ATE]: recuperarObjetoPorId(dadosAlteracao.identificador_momento_mostrar_curso_ate, CONSTANTE_MOSTRAR_CURSO_DATA_ATE, 'identificador')
        }

        if (dadosAlteracao.data_mostrar_curso_de) {
            detalhesCurso[DATA_MOSTRAR_CURSO] = moment.utc(dadosAlteracao.data_mostrar_curso_de);
        }

        if (dadosAlteracao.data_mostrar_curso_ate) {
            detalhesCurso[DATA_OCULTAR_CURSO] = moment.utc(dadosAlteracao.data_mostrar_curso_ate);
        }

        const classe = dadosAlteracao.classe;

        if (classe && classe.id) {
            detalhesCurso[AREA_TEMATICA] = {
                id: classe.id,
                descricao: classe.descricao
            }
        }

        formulario[DETALHES_CURSO] = detalhesCurso;
    }
}

const preencherDadosAnexos = (formulario, anexos) => {
    if (anexos && anexos.length) {
        let imagemCurso = anexos.filter(anexo => anexo.sigla_tipo_arquivo === CONSTANTE_TIPO_ARQUIVO_IMAGEM_CURSO);
        if (imagemCurso.length > 0) {
            imagemCurso = imagemCurso[0];
        }
        let anexosComplementares = anexos.filter(anexo => anexo.sigla_tipo_arquivo !== CONSTANTE_TIPO_ARQUIVO_IMAGEM_CURSO);

        formulario[DADOS_GERAIS][IMAGEM] = {
            id: imagemCurso.id,
            name: imagemCurso.nome,
            id_tipo_arquivo: imagemCurso.id_tipo_arquivo,
            url: imagemCurso.url
        };

        if (anexosComplementares?.length) {
            formulario[ANEXOS] = [];
            for (const anexo of anexosComplementares) {
                formulario[ANEXOS].push(
                    {
                        [ANEXO]: {
                            id: anexo.id,
                            name: anexo.nome,
                            url: anexo.url
                        },
                        [TIPO_ANEXO]: anexo.id_tipo_arquivo
                    }
                );
            }
        }
    }
}

const preencherDadosDecs = (formulario, dadosAlteracao) => {
    if (dadosAlteracao) {
        formulario[DECS] = dadosAlteracao.decs;
    }
}


const inicializarValoresFormulario = (dadosAlteracao) => {
    let formulario = { ...valoresPadrao };
    preencherDadosGerais(formulario, dadosAlteracao);
    preencherDetalhesCurso(formulario, dadosAlteracao);
    preencherDadosAnexos(formulario, dadosAlteracao?.anexos);
    preencherDadosDecs(formulario, dadosAlteracao);

    return formulario;
    formulario = {
        "dadosGerais": {
            "instituicaoResponsavel": {
                "id": 5244,
                "nome": "HOSPITAL UNIVERSITARIO",
                "cnes": 3157245,
                "id_cidade": 1,
                "sigla_estado": "SC",
                "id_estado": 1,
                "modalidades": [
                    {
                        "id": 25,
                        "sigla": "VLP",
                        "descricao": "Dermatologia",
                        "modalityuid": "1.2.840.10008.5.1.4.1.1.77.1.4",
                        "suporte_dimp": false,
                        "imprime_exame": false,
                        "identificador_tipo_modalidade": 1
                    },
                    {
                        "id": 22,
                        "sigla": "ECG",
                        "descricao": "Eletrocardiograma",
                        "modalityuid": "1.2.840.10008.5.1.4.1.1.9.1.2",
                        "suporte_dimp": false,
                        "imprime_exame": true,
                        "identificador_tipo_modalidade": 1
                    }
                ],
                "equipesSaude": [
                    {
                        "id": 56,
                        "nome": "Teste de código INE",
                        "codigo_ine": "9999999999"
                    }
                ]
            },
            "enderecoCurso": "https://www.google.com",
            "titulo": "titulo do curso",
            "descricao": "<p>descrição do curso, descrição do curso, descrição do curso, descrição do curso,&nbsp;</p>",
            "cargaHoraria": "10",
            "imagem": null,
            "formatoInstrucao": {
                "identificador": "A",
                "descricao": "Autoinstrucional"
            },
            "publico": true,
            "publicosAlvo": [],
            "categoria": {
                "id": 1,
                "descricao": "Cursos 2023",
                "ordenacao": 1,
                "ativa": true
            },
            "tituloBreve": "titulo breve",
            "contatoResponsavel": "contato@gmail.com",
            "formatoAtividade": {
                "id": 2,
                "descricao": "Síncrono",
                "ordenacao": 2,
                "ativo": true
            }
        },
        "detalhesCurso": {
            "dataInicialInscricao": "2023-10-01T07:09:00.000Z",
            "dataFinalInscricao": "2023-10-30T07:09:00.000Z",
            "dataInicialCurso": "2023-10-31T07:09:00.000Z",
            "dataFinalCurso": "2023-11-15T07:09:00.000Z",
            "prorrogacao": 15,
            "categoriasPublicoAlvo": [
                {
                    "id": 9,
                    "descricao": "Agentes Comunitários de Saúde",
                    "checked": false
                },
                {
                    "id": 1,
                    "descricao": "Alunos inscritos em minicurso do Telessaúde UFSC",
                    "checked": false
                }
            ],
            "areaTematica": {
                "id": 1,
                "descricao": "Planejamento, gestão e avaliação em saúde",
                "ordenacao": 1,
                "ativa": true
            },
            "categoriasAreaTematica": [
                {
                    "id": 5,
                    "id_cadastro_area_tematica_classe": 1,
                    "descricao": "Acolhimento e humanização",
                    "ordenacao": 5,
                    "descricao_classe": "Planejamento, gestão e avaliação em saúde",
                    "checked": false
                },
                {
                    "id": 4,
                    "id_cadastro_area_tematica_classe": 1,
                    "descricao": "Avaliação em saúde",
                    "ordenacao": 4,
                    "descricao_classe": "Planejamento, gestão e avaliação em saúde",
                    "checked": false
                }
            ],
            "dataProrrogada": null,
            "mostrarCursoDataDe": {
                "identificador": "AI",
                "descricao": "Antes do início das inscrições"
            },
            "dataMostrarCurso": "2023-10-19T07:12:00.000Z",
            "mostrarCursoDataAte": {
                "identificador": "PC",
                "descricao": "Após o final do curso"
            },
            "dataOcultarCurso": "2023-11-30T07:13:00.000Z",
            "numeroVagas": "10"
        },
        "anexos": [
            {}
        ],
        "anexosExcluidos": [],
        "decs": [
            {
                "id": "D08.811.682.047.436.174",
                "descritor": "11-beta-Hidroxiesteróide Desidrogenases",
                "descritor_antecessor": "Hidroxiesteróide Desidrogenases"
            },
            {
                "id": "D08.811.682.047.436.174.300",
                "descritor": "11-beta-Hidroxiesteróide Desidrogenase Tipo 1",
                "descritor_antecessor": "11-beta-Hidroxiesteróide Desidrogenases"
            }
        ]
    };

    return formulario;
}

const formatarCadastro = (dados) => {
    const formData = new FormData();
    const dadosAnexos = [];

    const formatarDatas = (chaves) => {
        chaves.forEach(chave => {
            dados.detalhesCurso[chave] = formatarDataParaYYYYMMDD(dados.detalhesCurso[chave]);
        });
    };

    const appendComoJSON = (chave) => {
        formData.append(chave, JSON.stringify(dados[chave]));
    };

    const imagemCurso = dados.dadosGerais.imagem;
    if (imagemCurso && !imagemCurso.id && (imagemCurso instanceof File)) {
        formData.append('imagemCurso', imagemCurso);
        formData.append('idTipoArquivoImagemCurso', imagemCurso.id_tipo_arquivo);
    }

    dados.anexos.forEach((anexoCompleto, index) => {
        const anexo = anexoCompleto.anexo;
        const tipoAnexo = anexoCompleto.tipoAnexo;
        if (anexo && (anexo instanceof File)) {
            formData.append(`anexos.${index}`, anexo);
            dadosAnexos.push(
                {
                    id_tipo_arquivo: tipoAnexo.id
                }
            )
        }
    });

    formData.append('dadosAnexos', JSON.stringify(dadosAnexos));

    formatarDatas(['dataInicialInscricao', 'dataFinalInscricao', 'dataInicialCurso', 'dataFinalCurso']);
    ['anexosExcluidos', 'anexosAlterados', 'dadosGerais', 'detalhesCurso', 'decs', 'dadosInscricoes'].forEach(appendComoJSON);

    if (dados.id) {
        formData.append('id', dados.id);
    }

    return formData;
};

const formatarDataParaYYYYMMDD = (data) => {
    const momentData = moment(data);
    return momentData.isValid() ? momentData.format('YYYY-MM-DD') : data;
}

const verificarErrosOrdemPrecedencia = (erros, secao) => {
    const ordemPrecedencia = [DADOS_GERAIS, DETALHES_CURSO, DECS];
    return ordemPrecedencia.indexOf(secao) === -1 && ordemPrecedencia.some(ordem => erros[ordem]);
};

export default { inicializarValoresFormulario, formatarCadastro, verificarErrosOrdemPrecedencia, formatarDataParaYYYYMMDD };