import React, { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { withRouter, useLocation, useHistory } from "react-router-dom";
import { MateriaisSuporte, SttHeader, SttHelpdeskShortcut, SttMainNavigationBar, SttNotificationScreen, SttSplashScreen, SttTranslateHook } from '@stt-componentes/core';
import { temPermissaoRBAC } from '../security/acl';
import { PERMISSOES } from '../common/Constants';
import { getHeaders } from '../request';

const Menu = ({ user }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const history = useHistory();
    const location = useLocation();
    const [tabAtual, setTabAtual] = useState(0);
    const [submenus, setSubmenus] = useState([]);
    const [openSS, setOpenSS] = useState(false);
    const [openMS, setOpenMS] = useState(false);
    const [exibirNotificacao, setExibirNotificacao] = useState(false);

    useEffect(() => {
        let menuOptions = submenus;
        if (user.credenciais) {
            //Tratamento de permissão do usuário
            if (!temPermissaoRBAC(user, PERMISSOES.TELEEDUCACAO)) {
                history.push(`/nao-autorizado`);
                return;
            }

            setExibirNotificacao(true);
            if (temPermissaoRBAC(user, PERMISSOES.PESQUISAR_WEB)) {
                menuOptions = [...menuOptions, {
                    id: '/webconferencia',
                    text: strings.webconferencia,
                    onClick: () => {
                        if (history.location.pathname !== '/webconferencia') {
                            history.push("/webconferencia");
                        }
                    }
                }];
            }

            if (temPermissaoRBAC(user, PERMISSOES.PESQUISAR_CURSO)) {
                menuOptions = [...menuOptions, {
                    id: '/pesquisa/curso',
                    text: strings.cursos,
                    onClick: () => {
                        if (history.location.pathname !== '/pesquisa/curso') {
                            history.push("/pesquisa/curso");
                        }
                    }
                }];
            }

            if (temPermissaoRBAC(user, PERMISSOES.ADMINISTRATIVO)) {
                menuOptions = [...menuOptions, {
                    id: '/administrativo',
                    text: strings.administrativo,
                    onClick: () => {
                        if (history.location.pathname !== '/administrativo') {
                            history.push("/administrativo");
                        }
                    }
                }];
            }

            setTabAtual(0);
            setSubmenus(menuOptions);
        }
    }, [user.credenciais]);

    useEffect(() => {
        submenus.forEach((menu, i) => {
            if (menu.id === location.pathname) {
                setTabAtual(i);
            }
        });
    }, [location.pathname, submenus]);

    return (
        <div>
            <SttHeader
                config={global.gConfig}
                titulo={global.gConfig.instancia_nome}
                opcoesDuvida={{
                    onClickNotasAtualizacao: () => setOpenSS(!openSS),
                    onClickMateriaisSuporte: () => setOpenMS(!openMS),
                    strings
                }}
            />
            <SttSplashScreen
                modulo={global.gConfig.modulo_nome}
                versao={global.gConfig.modulo_versao}
                novidades={global.gConfig.modulo_novidades}
                textoAjuda={global.gConfig.texto_ajuda}
                perfisRBAC={user.perfisRBAC}
                open={openSS}
                setOpen={setOpenSS}
            />
            <SttMainNavigationBar
                config={global.gConfig}
                titulo={global.gConfig.modulo_nome}
                submenus={submenus}
                selectedTab={tabAtual}
            />
            <MateriaisSuporte
                strings={strings}
                config={global.gConfig}
                headers={getHeaders()}
                open={openMS}
                setOpen={setOpenMS}
            />
            {
                exibirNotificacao &&
                <SttNotificationScreen
                    config={global.gConfig}
                    strings={strings}
                    headers={getHeaders()}
                    perfisRBAC={user.perfisRBAC}
                />
            }
            <SttHelpdeskShortcut
                config={global.gConfig}
                usuario={user}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps)(withRouter(Menu));